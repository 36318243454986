import React, { memo, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { FixedSizeList as List, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import CircularProgress from '@material-ui/core/CircularProgress';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import styles from './PdfDocument.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const renderPage = memo(
  ({ index, style }) => (
    <div style={style}>
      <Page
        pageNumber={index + 1}
        scale={1}
        width={612}
        height={792}
        className={styles['react-pdf__Page']}
      />
    </div>
  ),
  areEqual
);

const PdfDocument = forwardRef(({ pdfFile, onDocumentLoad }, ref) => {
  const [pageHeight, setPageHeight] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const listRef = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollToPage
  }));

  const documentLoadHandler = async (pdf) => {
    const firstPage = await pdf.getPage(1);
    const pageHeight = firstPage.view[3];
    const pageSplitSize = 10;

    setPageHeight(pageHeight + pageSplitSize);
    setNumberOfPages(pdf.numPages);
    await onDocumentLoad(pdf);
  };

  const scrollToPage = (pageIndex) => {
    listRef.current.scrollToItem(pageIndex, 'start');
  }

  return (
    <Document
      file={pdfFile}
      onLoadSuccess={documentLoadHandler}
      onItemClick={(e) => scrollToPage(e.pageIndex)}
      loading={<CircularProgress />}
      className={styles.documentContainer}
    >
      <AutoSizer>
        {({ height: pagesHeight, width: pagesWidth }) => (
          <List
            ref={listRef}
            height={pagesHeight}
            width={pagesWidth}
            itemCount={numberOfPages}
            itemSize={pageHeight}
          >
            {renderPage}
          </List>
        )}
      </AutoSizer>
    </Document>
  );
});

export default memo(PdfDocument);

import "core-js/stable";
import "regenerator-runtime/runtime";
import 'react-app-polyfill/ie11';
import 'filepond-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Polyfills
if (typeof Promise.withResolvers === "undefined") {
  if (window) {
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    window.Promise.withResolvers = function () {
      let resolve, reject
      const promise = new Promise((res, rej) => {
        resolve = res
        reject = rej
      })
      return { promise, resolve, reject }
    }
  } else {
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    global.Promise.withResolvers = function () {
      let resolve, reject
      const promise = new Promise((res, rej) => {
        resolve = res
        reject = rej
      })
      return { promise, resolve, reject }
    }
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

const styles = theme => ({
    orderLink: {
        color: '#f48232',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        }
    }
});

export default styles;
const styles = theme => ({
    searchPaper: {
        padding: theme.spacing(1)
    },

    // inputs
    formControl: {
    },
    inputLabel: {
        color: '#2c3e50',
        display: 'inline-block',
        fontWeight: 700
    },
});

export default styles;
import React, { useState } from 'react';
import styles from './Pdf.module.css';
import classNames from 'classnames';

const OutlineItem = ({ outlineItem, onOutlineItemClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const onClickHandler = (e) => {
    e.preventDefault();
    onOutlineItemClick(outlineItem.dest);
  };

  const onTreeItemToggleClick = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.treeItem}>
      {outlineItem.items && outlineItem.items.length > 0 && (
        <div
          className={classNames(styles.treeItemToggler, {
            [styles.treeItemsHidden]: !isExpanded,
          })}
          onClick={onTreeItemToggleClick}
        ></div>
      )}
      <a href="/" onClick={onClickHandler}>
        {outlineItem.title}
      </a>
      {outlineItem.items && outlineItem.items.length > 0 && (
        <div className={styles.treeItems}>
          {outlineItem.items.map((item, index) => (
            <OutlineItem
              key={index}
              outlineItem={item}
              onOutlineItemClick={onOutlineItemClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default OutlineItem;

import React from 'react';
import { NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const drawerWidth = 200;
const appBarHeight = 100;

const styles = theme => ({
	// drawer
	drawer: {
		width: drawerWidth,
		flexShrink: 0
	},
	drawerPaper: {
		marginTop: appBarHeight,
		width: drawerWidth,
		backgroundColor: '#575757',
		color: '#DFDFDF',
		border: 0
	},
	listItemIcon: {
		color: '#DFDFDF',
		minWidth: '35px'
	},
	listItem: {
		borderBottom: 'solid 1px #ffffff',
		paddingLeft: '12px',
		paddingRight: '12px'
	},
	navLink: {
		color: '#DFDFDF',
		textDecoration: 'none',
	}
});

function SideBar(props) {
	const { classes } = props;

	return (
		<Drawer
			className={classes.drawer}
			variant='permanent'
			classes={{
				paper: classes.drawerPaper
			}}
			anchor='left'
		>
			<Divider />
			<List>
				<NavLink data-testid='myCaseManager' className={classes.navLink} to='/'>
					<ListItem button className={classes.listItem}>
						<ListItemText primary='My Case Manager' />
					</ListItem>
				</NavLink>
				<NavLink data-testid='myProfile' className={classes.navLink} to='/myprofile'>
					<ListItem button className={classes.listItem}>
						<ListItemText primary='My Profile' />
					</ListItem>
				</NavLink>
			</List>
		</Drawer>
	);
}

export default withStyles(styles)(SideBar);

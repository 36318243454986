const styles = theme => ({
    modalTitle: {
        fontSize: '26px;',
        color: '#2c3e50'
    },
    invalidLinkPaper: {
        padding: theme.spacing(2, 2),
    },
    infoContainer: {
        marginBottom: '20px',
        borderRadius: '4px',
        color: '#fff',
        fontSize: '15px',
        verticalAlign: 'middle',
        textAlign: 'center'
    },
    // Alerts
    errorAlert: {
        textAlign: 'center',
        backgroundColor: '#e74c3c',
        fontSize: '15px'
    },
    // Common
    hidden: {
        display: 'none'
    },
    loginLogo: {
        width: '210px',
        height: '75px',
        margin: '10px auto 30px',
        backgroundSize: 'cover'
    }
});

export default styles;
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link, Paper } from '@material-ui/core';
import styles from './ResetComplete.style';

const ResetComplete = props => {
	const classes = props.classes;

	return (
		<div className={classes.root}>
			<Paper className={classes.boxPaper}>
				<Grid container direction='column' justify='center' alignItems='center'>
					<img
						src='./images/MyOntellus_Logo-Portal_GrayText.png'
						height='80px'
						width='210px'
						alt='Ontellus Logo'
					/>
					<Paper className={classes.msgPaper}>
						Password reset successfully. Click{' '}
						<Link className={classes.msgLink} href='/'>
							Here
						</Link>{' '}
						to return to the login page.
					</Paper>
				</Grid>
			</Paper>
		</div>
	);
};

export default withStyles(styles)(ResetComplete);

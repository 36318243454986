import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import Routes from './AppRoutes';

import './AxiosInterceptors';
import './App.css';

const themeName = 'Ontellus Colors';

const theme = createTheme({
	themeName,
	typography: {
		useNextVariants: true,
		fontFamily: 'Lato,"Helvetica Neue",Helvetica,Arial,sans-serif',
		fontSize: 15,
		lineHeight: 1.428571429
	},
	overrides: {
		MuiInput: {
			underline: {
				'&&&&:hover:before': {
					borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
				}
			}
		}
	}
});

function App() {
	return (
		<React.Fragment>
			<CssBaseline />
			<MuiThemeProvider theme={theme}>
				<Routes />
			</MuiThemeProvider>
		</React.Fragment>
	);
}

export default App;

import { UserManager } from 'oidc-client';
import { useNavigate } from 'react-router-dom';

const settings = window.APP_SETTINGS;

const oidcClientSettings = {
	authority: `${settings.IDP_AUTHORITY}/idsrv/connect/authorize`,
	client_id: settings.IDP_CLIENT_ID,
	response_type: 'id_token token',
	scope: 'myo-api openid',
	redirect_uri: `${window.location.origin}/signin-callback.html`,
	silent_redirect_uri: `${window.location.origin}/silent-callback.html`,
	acr_values: 'tenant:UserCredentials',
	metadata: {
		issuer: `${settings.IDP_AUTHORITY}/idsrv`,
		authorization_endpoint: `${settings.IDP_AUTHORITY}/idsrv/connect/authorize`,
		userinfo_endpoint: `${settings.IDP_AUTHORITY}/idsrv/connect/userinfo`,
		end_session_endpoint: `${settings.IDP_AUTHORITY}/idsrv/connect/endsession`,
		jwks_uri: `${settings.IDP_AUTHORITY}/idsrv/.well-known/jwks`
	},
	automaticSilentRenew: true
};

const userManager = new UserManager(oidcClientSettings);

userManager.events.addSilentRenewError(function() {
	const navigate = useNavigate();
	userManager.removeUser();
	navigate('/tokenfetcherror');
});

export const login = returnPath => {
	return userManager.signinRedirect({ state: { returnPath } });
};

export const logout = returnPath => {
	return userManager.signoutRedirect({ state: { returnPath } });
};

// Get logged in user info.
export const getUser = async () => {
    const loggedUser = await userManager.getUser();
	return (
		loggedUser && {
			expired: loggedUser.expired,
			access_token: loggedUser.access_token,
			tenant: loggedUser.profile.sub.split('-')[0],
			id: loggedUser.profile.sub.split('-')[1]
		}
	);
};

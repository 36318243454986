import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
	Paper,
	withStyles,
	Grid,
	InputLabel,
	FormControl,
	Button,
	CircularProgress
} from '@material-ui/core';

import { getExpert, updateExpert } from '../../Services/ExpertService';
import { getStates } from '../../Services/StateService';
import BootstrapInput from '../Shared/BootstrapInput/BootstrapInput';
import styles from './UserProfile.style';
import SelectInput from '../Shared/SelectInput/SelectInput';
import ConfirmDialog from '../Shared/ConfirmDialog/ConfirmDialog';
import { useNavigate } from 'react-router-dom';

const UserProfile = props => {
	const navigate = useNavigate();
	// Props
	const { classes } = props;

	// Constants
	const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[<>)(#?!@$%^&*_,-]).{8,}$/;
	const zipRegex = /^[0-9]{5,5}$/;
	const initialValues = {
		email: '',
		firstName: '',
		lastName: '',
		company: '',
		address: '',
		city: '',
		state: '',
		zip: '',
		phone: '',
		fax: '',
		password: '',
		confirmPassword: ''
	};
	const validationSchema = Yup.object().shape({
		firstName: Yup.string()
			.trim()
			.required('Required'),
		lastName: Yup.string()
			.trim()
			.required('Required'),
		company: Yup.string()
			.trim()
			.required('Required'),
		address: Yup.string().trim(),
		city: Yup.string().trim(),
		state: Yup.number(),
		zip: Yup.string()
			.required('Required')
			.matches(zipRegex, 'Zip code must be 5 digits'),
		phone: Yup.string()
			.trim()
			.transform(val => val.replace(/-/g, '').replace(/_/g, ''))
			.min(10, 'Phone must be at least 10 digits')
			.max(10, 'Phone must be at most 10 digits'),
		fax: Yup.string()
			.trim()
			.transform(val => val.replace(/-/g, '').replace(/_/g, ''))
			.min(10, 'Fax must be at least 10 digits')
			.max(10, 'Fax must be at most 10 digits'),
		password: Yup.string()
			.trim()
			.matches(passwordRegex),
		confirmPassword: Yup.string().when('password', {
			is: val => (val && val.length > 0 ? true : false),
			then: Yup.string()
				.oneOf([Yup.ref('password')], "Passwords don't match.")
				.required('Confirm password field is required.')
		})
	});
	// End of Constatnts

	// States
	const [userValues, setUserValues] = useState(initialValues);
	const [origUserValues, setOrigUserValues] = useState({});
	const [stateOptions, setStateOptions] = useState([]);
	const [errorMsg, setErrorMsg] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isConfirmOpen, setConfirmOpen] = useState(false);
	const [isLoaded, setLoaded] = useState(false);
	// End of States

	// Effects
	useEffect(() => {
		// Get logged in expert
		const fetchUser = async () => {
			try {
				const result = await getExpert();
				setUserValues({ ...result.data, password: '', confirmPassword: '' });
				setOrigUserValues({ ...result.data, password: '', confirmPassword: '' });
				setLoaded(true);
			} catch (e) {
				setErrorMsg('An error occured while fetching user data.');
				console.error(e);
			}
		};
		fetchUser();

		// Get states
		const fetchStates = async () => {
			try {
				const result = await getStates();
				const states = result.data.map(state => ({
					value: state.id,
					label: state.name
				}));
				setStateOptions(states);
			} catch (e) {
				setErrorMsg('An error occured while fetching states.');
				console.error(e);
			}
		};
		fetchStates();
	}, []);
	// End of Effects

	// Events
	const onFormReset = () => {
		setUserValues({...origUserValues});
	};
	// End of Events

	return (
		isLoaded && (<React.Fragment>
			<Paper elevation={0} className={classes.titlePaper}>
				<h4 className={classes.title}>My Profile</h4>
			</Paper>
			<Formik
				enableReinitialize
				initialValues={userValues}
				onReset={onFormReset}
				validationSchema={validationSchema}
				onSubmit={async (values) => {
						setErrorMsg('');
						setIsSubmitting(true);
						try {
							await updateExpert(values);
							setIsSubmitting(false);
							navigate('/');
						} catch (e) {
							setIsSubmitting(false);
							setErrorMsg(
								'There was an error in saving the request. Please contact the administrator.'
							);
							window.scrollTo(0, 0);
							console.error(e);
						}
				}}
			>
				{props => {
					const {
						values,
						touched,
						errors,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						setFieldValue
					} = props;

					return (
						<React.Fragment>
							<Grid container>
								<Grid item xs={8}>
									<Paper className={classes.containerPaper}>
										{errorMsg && (
											<Paper className={classes.errorMsg}>
												<strong>Error: </strong>
												{errorMsg}
											</Paper>
										)}
										<form
											className={classes.form}
											noValidate
											autoComplete='off'
											onSubmit={handleSubmit}
										>
											<Grid container spacing={2}>
												<Grid
													item
													xs={12}
													md={3}
													className={classes.labelGridItem}
												>
													<InputLabel className={classes.inputLabel}>
														First Name *
													</InputLabel>
												</Grid>
												<Grid
													item
													xs={12}
													md={9}
													className={classes.inputGridItem}
												>
													<FormControl required fullWidth>
														<BootstrapInput
															id='firstName'
															autoComplete='off'
															maxLength='50'
															value={values.firstName}
															onChange={handleChange}
															onBlur={handleBlur}
															error={errors.firstName && touched.firstName}
														/>
													</FormControl>
													{errors.firstName && touched.firstName && (
														<span className={classes.errorHint}>
															{errors.firstName}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													md={3}
													className={classes.labelGridItem}
												>
													<InputLabel className={classes.inputLabel}>
														Last Name *
													</InputLabel>
												</Grid>
												<Grid
													item
													xs={12}
													md={9}
													className={classes.inputGridItem}
												>
													<FormControl required fullWidth>
														<BootstrapInput
															id='lastName'
															autoComplete='off'
															maxLength='50'
															value={values.lastName}
															onChange={handleChange}
															onBlur={handleBlur}
															error={errors.lastName && touched.lastName}
														/>
													</FormControl>
													{errors.lastName && touched.lastName && (
														<span className={classes.errorHint}>
															{errors.lastName}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													md={3}
													className={classes.labelGridItem}
												>
													<InputLabel className={classes.inputLabel}>
														Company *
													</InputLabel>
												</Grid>
												<Grid
													item
													xs={12}
													md={9}
													className={classes.inputGridItem}
												>
													<FormControl required fullWidth>
														<BootstrapInput
															id='company'
															autoComplete='off'
															maxLength='150'
															value={values.company}
															onChange={handleChange}
															onBlur={handleBlur}
															error={errors.company && touched.company}
														/>
													</FormControl>
													{errors.company && touched.company && (
														<span className={classes.errorHint}>
															{errors.company}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													md={3}
													className={classes.labelGridItem}
												>
													<InputLabel className={classes.inputLabel}>
														Address
													</InputLabel>
												</Grid>
												<Grid
													item
													xs={12}
													md={9}
													className={classes.inputGridItem}
												>
													<FormControl required fullWidth>
														<BootstrapInput
															id='address'
															autoComplete='off'
															maxLength='250'
															value={values.address}
															onChange={handleChange}
															onBlur={handleBlur}
															error={errors.address && touched.address}
														/>
													</FormControl>
													{errors.address && touched.address && (
														<span className={classes.errorHint}>
															{errors.address}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													md={3}
													className={classes.labelGridItem}
												>
													<InputLabel className={classes.inputLabel}>
														City
													</InputLabel>
												</Grid>
												<Grid
													item
													xs={12}
													md={9}
													className={classes.inputGridItem}
												>
													<FormControl required fullWidth>
														<BootstrapInput
															id='city'
															autoComplete='off'
															maxLength='50'
															value={values.city}
															onChange={handleChange}
															onBlur={handleBlur}
															error={errors.city && touched.city}
														/>
													</FormControl>
													{errors.city && touched.city && (
														<span className={classes.errorHint}>
															{errors.city}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													md={3}
													className={classes.labelGridItem}
												>
													<InputLabel className={classes.inputLabel}>
														State
													</InputLabel>
												</Grid>
												<Grid
													item
													xs={12}
													md={9}
													className={classes.inputGridItem}
												>
													<FormControl required fullWidth>
														<SelectInput
															id='state'
															value={values.state}
															options={stateOptions}
															handleChange={val => setFieldValue('state', val.value)}
															onBlur={handleBlur}
															error={errors.state && touched.state}
														/>
													</FormControl>
													{errors.state && touched.state && (
														<span className={classes.errorHint}>
															{errors.state}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													md={3}
													className={classes.labelGridItem}
												>
													<InputLabel className={classes.inputLabel}>
														Zip *
													</InputLabel>
												</Grid>
												<Grid
													item
													xs={12}
													md={9}
													className={classes.inputGridItem}
												>
													<FormControl required fullWidth>
														<BootstrapInput
															id='zip'
															autoComplete='off'
															maxLength='5'
															value={values.zip}
															onChange={handleChange}
															onBlur={handleBlur}
															error={errors.zip && touched.zip}
														/>
													</FormControl>
													{errors.zip && touched.zip && (
														<span className={classes.errorHint}>
															{errors.zip}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													md={3}
													className={classes.labelGridItem}
												>
													<InputLabel className={classes.inputLabel}>
														Phone
													</InputLabel>
												</Grid>
												<Grid
													item
													xs={12}
													md={4}
													className={classes.inputGridItem}
												>
													<FormControl required fullWidth>
														<BootstrapInput
															id='phone'
															autoComplete='off'
															mask='999-999-9999'
															value={values.phone}
															onChange={handleChange}
															onBlur={handleBlur}
															error={errors.phone && touched.phone}
														/>
													</FormControl>
													{errors.phone && touched.phone && (
														<span className={classes.errorHint}>
															{errors.phone}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													md={1}
													className={classes.labelGridItem}
												>
													<InputLabel className={classes.inputLabel}>
														Fax
													</InputLabel>
												</Grid>
												<Grid
													item
													xs={12}
													md={4}
													className={classes.inputGridItem}
												>
													<FormControl required fullWidth>
														<BootstrapInput
															id='fax'
															autoComplete='off'
															mask='999-999-9999'
															value={values.fax}
															onChange={handleChange}
															onBlur={handleBlur}
															error={errors.fax && touched.fax}
														/>
													</FormControl>
													{errors.fax && touched.fax && (
														<span className={classes.errorHint}>
															{errors.fax}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													md={3}
													className={classes.labelGridItem}
												>
													<InputLabel className={classes.inputLabel}>
														Email *
													</InputLabel>
												</Grid>
												<Grid
													item
													xs={12}
													md={9}
													className={classes.inputGridItem}
												>
													<FormControl required fullWidth>
														<BootstrapInput
															id='email'
															disabled
															maxLength='18'
															value={values.email}
															onChange={handleChange}
															onBlur={handleBlur}
															error={errors.email && touched.email}
														/>
													</FormControl>
												</Grid>
												<Grid
													item
													xs={12}
													md={3}
													className={classes.labelGridItem}
												>
													<InputLabel className={classes.inputLabel}>
														Change Password
													</InputLabel>
												</Grid>
												<Grid
													item
													xs={12}
													md={9}
													className={classes.inputGridItem}
												>
													<FormControl fullWidth>
														<BootstrapInput
															id='password'
															type='password'
															autoComplete='off'
															maxLength='64'
															value={values.password}
															onChange={handleChange}
															onBlur={handleBlur}
															error={errors.password && touched.password}
														/>
													</FormControl>
													<span
														className={[
															classes.cueText,
															errors.password &&
																touched.password &&
																classes.cueTextDanger
														].join(' ')}
													>
														Must contain min 8 characters, 1 uppercase, 1 digit
														and 1 special character.
													</span>
												</Grid>
												<Grid
													item
													xs={12}
													md={3}
													className={classes.labelGridItem}
												>
													<InputLabel className={classes.inputLabel}>
														Confirm Password
													</InputLabel>
												</Grid>
												<Grid
													item
													xs={12}
													md={9}
													className={classes.inputGridItem}
												>
													<FormControl required fullWidth>
														<BootstrapInput
															id='confirmPassword'
															type='password'
															autoComplete='off'
															maxLength='64'
															value={values.confirmPassword}
															onChange={handleChange}
															onBlur={handleBlur}
															error={
																errors.confirmPassword &&
																touched.confirmPassword
															}
														/>
													</FormControl>
													{errors.confirmPassword &&
														touched.confirmPassword && (
															<span className={classes.errorHint}>
																{errors.confirmPassword}
															</span>
														)}
												</Grid>
												<Grid item xs={12} className={classes.actions}>
													<Button
														variant='contained'
														id='reset'
														className={classes.clearButton}
														disabled={isSubmitting}
														onClick={() => setConfirmOpen(true)}
													>
														Clear
													</Button>
													<ConfirmDialog
														title="Clear Form"
														id="confirm-dialog"
														open={isConfirmOpen}
														setOpen={setConfirmOpen}
														onConfirm={handleReset}
													>
														Clear all data entered on this screen?
													</ConfirmDialog>
													<Button
														type='submit'
														id='submit'
														variant='contained'
														className={classes.submitButton}
														disabled={
															Object.keys(errors).length > 0 || isSubmitting
														}
													>
														{isSubmitting ? (
															<CircularProgress
																color='inherit'
																size={25.6}
																thickness={8}
															/>
														) : (
															'Save'
														)}
													</Button>
												</Grid>
											</Grid>
										</form>
									</Paper>
								</Grid>
							</Grid>
						</React.Fragment>
					);
				}}
			</Formik>
		</React.Fragment>)
	);
};

export default withStyles(styles)(UserProfile);

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { useDebounce } from 'use-debounce';

import styles from './CaseManagerSearch.style';
import BootstrapInput from '../../Shared/BootstrapInput/BootstrapInput';

const CaseManagerSearch = props => {
	const { classes, onFilterChange } = props;

	const [caseNo, setCaseNo] = useState('');
	const [patient, setPatient] = useState('');
	const [contactName, setContactName] = useState('');
	const [claimNo, setClaimNo] = useState('');
	const [cliMatNo, setCliMatNo] = useState('');
	const [searchFilters, setSearchFilters] = useState({});

	const [debouncedSearchFilters] = useDebounce(searchFilters, 500);

	useEffect(() => {
		setSearchFilters({ caseNo, patient, contactName, claimNo, cliMatNo });
	}, [caseNo, patient, contactName, claimNo, cliMatNo]);

	useEffect(() => {
		if (debouncedSearchFilters) {
			onFilterChange(debouncedSearchFilters);
		}
	}, [debouncedSearchFilters, onFilterChange]);

	const onOrderNoChange = e => {
		setCaseNo(e.target.value);
	};

	const onRecordsOfChange = e => {
		setPatient(e.target.value);
	};

	const onContactChange = e => {
		setContactName(e.target.value);
	};

	const onClaimNoChange = e => {
		setClaimNo(e.target.value);
	};

	const onFirmFileNoChange = e => {
		setCliMatNo(e.target.value);
	};

	return (
		<div>
			<Paper elevation={0} className={classes.searchPaper}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<InputLabel shrink className={classes.inputLabel}>
							Order Number
						</InputLabel>
						<FormControl fullWidth>
							<BootstrapInput
								id='orderNo'
								value={caseNo}
								maxLength='10'
								onChange={onOrderNoChange}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<InputLabel shrink className={classes.inputLabel}>
							Records Of
						</InputLabel>
						<FormControl fullWidth>
							<BootstrapInput
								id='recordsOf'
								value={patient}
								maxLength='80'
								onChange={onRecordsOfChange}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<InputLabel shrink className={classes.inputLabel}>
							Contact
						</InputLabel>
						<FormControl fullWidth>
							<BootstrapInput
								id='contact'
								value={contactName}
								maxLength='80'
								onChange={onContactChange}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={3}>
						<InputLabel shrink className={classes.inputLabel}>
							Claim No
						</InputLabel>
						<FormControl fullWidth>
							<BootstrapInput
								id='claimNo'
								value={claimNo}
								maxLength='40'
								onChange={onClaimNoChange}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={3}>
						<InputLabel shrink className={classes.inputLabel}>
							Firm File No
						</InputLabel>
						<FormControl fullWidth>
							<BootstrapInput
								id='firmFileNo'
								value={cliMatNo}
								maxLength='24'
								onChange={onFirmFileNoChange}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export default withStyles(styles)(CaseManagerSearch);

const styles = theme => ({
    dialogPaper: {
        width: '100%',
        fontFamily: 'Lato,"Helvetica Neue",Helvetica,Arial,sans-serif',
    },
    dialogHeader: {
        minHeight: '16.43',
        padding: 15,
        fontWeight: 700,
        fontSize: 20,
        textTransform: 'uppercase',
        borderBottom: '1px solid #e5e5e5'
    },
    dialogBody: {
        padding: 20,
    },
    headerContent: {
		minWidth: 650,
	},
	odd: {
		backgroundColor: '#f9f9f9',
		
	},
	even: {
		backgroundColor: '#0000',
	},
    headerTitle:{ 
        fontWeight:'bold', 
        marginRight:3
    },

     // table action buttons
     viewBtn: {
        textTransform: 'none',
        backgroundColor: '#f39c12',
        borderColor: '#f39c12',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#d2850b',
            borderColor: '#be780a'
        }
    },
    viewSection: {
      display: "flex",
      "& > *": {
        marginRight: 8,
      },
    },
});

export default styles;
const tableStyles = theme => ({
    // general
    tableTitle: {
        padding: '20px 0 20px 8px',
        fontFamily: 'Lato,"Helvetica Neue",Helvetica,Arial,sans-serif',
        fontWeight: 700,
        fontSize: 20
    },

    // table action buttons
    viewBtn: {
        textTransform: 'none',
        backgroundColor: '#f39c12',
        borderColor: '#f39c12',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#d2850b',
            borderColor: '#be780a'
        }
    },
    viewSection: {
      display: "flex",
      "& > *": {
        marginRight: 8,
      },
    },
});

export { tableStyles };
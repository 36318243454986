import axios from 'axios';
import { getUser } from './Services/AuthService';

axios.interceptors.request.use(
	config => {
		return getUser()
			.then(user => {
				if (user != null && user.access_token && !user.expired) {
					config.headers.Authorization = `Bearer ${user.access_token}`;
				}

				const ua = navigator.userAgent;
				/* MSIE used to detect old browsers and Trident used to newer ones*/
				const is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
					
				// if on Internet Explorer add If-Modified-Since header to 0 to prevent caching.
				if (is_ie) {
					config.headers['If-Modified-Since'] = '0';
				}

				return config;
			})
			.catch(error => {
				console.log(error);
			});
	},
	error => {
		// Let the error bubble to the original call.
		return Promise.reject(error);
	}
);

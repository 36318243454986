import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment';

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
	return order === 'desc'
		? (a, b) => desc(a, b, orderBy)
		: (a, b) => -desc(a, b, orderBy);
}

function formatCellValue(columnProps, row) {
	if (!row[columnProps.id]) return 'N/A';
	if (columnProps.isDate) {
		const date = columnProps.dateOptions && columnProps.dateOptions.isUTC ?
			moment(row[columnProps.id]).utc(columnProps.dateOptions.convertUTCToLocalTimeZone) : moment(row[columnProps.id]);
		return date.format('MM/DD/YYYY');
	} else {
		return row[columnProps.id];
	}
}

function changeRow(rows, row) {
	const newRows = [];
	for (let i = 0; i < rows.length; i++) {
		if (i === row.index) {
			newRows.push(row);
		} else {
			newRows.push(rows.find(r => r.index === i));
		}
	}
	return newRows;
}

function ActionComponent(props) {
	const { component, row, rows, setRows } = props;
	const Component = component.type;
	const setRow = row => {
		setRows(changeRow(rows, row));
	};

	return (
		<Component
			{...component.props}
			row={row}
			setRow={setRow}
			rows={rows}
			setRows={setRows}
		/>
	);
}

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort, headCells } = props;

	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						className={classes.tableHeaderCell}
						key={headCell.id}
						sortDirection={orderBy === headCell.id ? order : false}
						style={{ width: headCell.width || 'inherit' }}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
							IconComponent={ArrowDropDownIcon}
							classes={{
								root: classes.sortLabel,
								icon: [
									classes.sortIcon,
									orderBy === headCell.id ? {} : classes.inActiveSortIcon
								].join(' ')
							}}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired
};

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		position: 'relative'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 650
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	},
	tableHeaderCell: {
		fontSize: 15,
		fontFamily: 'Lato,"Helvetica Neue",Helvetica,Arial,sans-serif',
		color: '#2c3e50',
		textAlign: 'left',
		fontWeight: '700',
		padding: theme.spacing(1),
		border: '1px solid #ecf0f1'
	},
	odd: {
		backgroundColor: '#f9f9f9',
		'&:hover': {
			backgroundColor: '#ecf0f1'
		}
	},
	even: {
		backgroundColor: '#0000',
		'&:hover': {
			backgroundColor: '#ecf0f1'
		}
	},
	cellBody: {
		border: '1px solid #ecf0f1',
		color: '#2c3e50',
		padding: theme.spacing(1)
	},
	link: {
		textDecoration: 'none'
	},
	sortLabel: {
		width: '100%'
	},
	sortIcon: {
		marginLeft: 'auto'
	},
	inActiveSortIcon: {
		opacity: '0.3'
	},
	loadingPanel: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.5);',
		zIndex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& > *': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			backgroundColor: '#fff',
			borderRadius: '0 0 4px 4px',
			margin: '1px',
			padding: theme.spacing(1),
			width: 150
		}
	},
	noDataAvailableRow : {
		fontSize : '15px',
		backgroundColor: '#f9f9f9'
	}
}));

export default function EnhancedTable(props) {
	const {
		rows, // the data source to populate the table.
		customCells, // custom action components to be rendered.
		/* 
			You must use the correct column id as the name of the custom cell for it to be rendered.
		*/
		headCells, // header cells properties.
		rowsPerPageOptions, // options to be displayed in rows per page dropdown.
		useDbPagination, // 'true' to use database pagination.
		onPageChange, // callback function executed on page change.
		showPagination, // 'true' to show pagination section.
		pageCount, // the count of rows diplayed per page.
		isLoading,
		fetchRowsPromise // A promise that takes the pageNo as a parameter and returns an object {rows: collection of rows, rowCount: total rows count} if it succeeds
	} = props;

	const classes = useStyles();
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState(headCells[0].id);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(
		showPagination
			? rowsPerPageOptions && rowsPerPageOptions.length
				? rowsPerPageOptions[0]
				: pageCount || 20
			: 20
	);
	const [dataRows, setDataRows] = useState([]);
	const [rowCount, setRowCount] = useState(0);
	const [isPromiseLoading, setIsPromiseLoading] = useState(false);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows =
		rowsPerPage -
		Math.min(
			rowsPerPage,
			(useDbPagination ? rowCount : dataRows.length) - page * rowsPerPage
		);

	// Asynchronous Fetch
	const fetchPromiseData = async pageNo => {
		if (!fetchRowsPromise) return;

		setIsPromiseLoading(true);
		try {
			const result = await fetchRowsPromise(pageNo);
			const { rows, rowCount } = result;
			// adding index column ro reserve original ordered index
			setDataRows(rows.map((row, index) => ({ ...row, index })));
			setRowCount(rowCount);
			setIsPromiseLoading(false);
		} catch (err) {
			setIsPromiseLoading(false);
			console.error(err);
		}
	};

	useEffect(() => {
		if (useDbPagination) {
			if (page > 0) {
				setPage(0);
			} else {
				fetchPromiseData(0);
			}
		} else {
			fetchPromiseData();
		}
	}, [fetchRowsPromise]);
	// End of Async Fetch

	// Synchronous Fetch
	useEffect(() => {
		if (rows) {
			// adding index column ro reserve original ordered index
			setDataRows(rows.map((row, index) => ({ ...row, index })));
		}
	}, [rows]);
	// End of Synchronous Fetch

	useEffect(() => {
		if (useDbPagination) {
			fetchPromiseData(page);
		}

		if (onPageChange) {
			onPageChange(page);
		}
	}, [page]);

	return (
        <div className={classes.root}>
            {(isLoading || isPromiseLoading) && (
                <div className={classes.loadingPanel}>
                    <Paper>
                        <CircularProgress color='inherit' />
                    </Paper>
                </div>
            )}
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table className={classes.table}>
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        {dataRows.length > 0 ? (
                            <TableBody>
                                {stableSort(dataRows, getSorting(order, orderBy))
                                    .slice(
                                        (useDbPagination ? 0 : page) * rowsPerPage,
                                        (useDbPagination ? 0 : page) * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const rowClass =
                                            (index + 1) % 2 === 0 ? classes.even : classes.odd;
                                        return (
                                            <TableRow
                                                className={rowClass}
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                {headCells.map((col) => {
                                                    return (
                                                        <TableCell
                                                            key={col.id + index}
                                                            title={
                                                                customCells &&
                                                                customCells[
                                                                    col.id
                                                                ]
                                                                    ? ''
                                                                    : formatCellValue(
                                                                          col,
                                                                          row
                                                                      )
                                                            }
                                                            className={
                                                                classes.cellBody
                                                            }
                                                        >
                                                            {customCells &&
                                                            customCells[
                                                                col.id
                                                            ] ? (
                                                                <ActionComponent
                                                                    component={
                                                                        customCells[
                                                                            col
                                                                                .id
                                                                        ]
                                                                    }
                                                                    row={row}
                                                                    rows={
                                                                        dataRows
                                                                    }
                                                                    setRows={
                                                                        setDataRows
                                                                    }
                                                                />
                                                            ) : (
                                                                formatCellValue(
                                                                    col,
                                                                    row
                                                                )
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow>
                                        <TableCell colSpan={headCells.length} />
                                    </TableRow>
                                )}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        colSpan={headCells.length}
                                        className={classes.noDataAvailableRow}
                                    >
                                        No data available in table
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                {showPagination && (
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions || []}
                        component='div'
                        count={useDbPagination ? rowCount : rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Paper>
        </div>
    );
}

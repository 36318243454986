const styles = theme => ({
    backgroundPaper: {
        backgroundImage: 'url(images/testimonial-woman-.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        padding: theme.spacing(10, 0),
        minHeight: '100%'
    },
    boxPaper: {
        padding: theme.spacing(4),
        margin: theme.spacing(2, 0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: '#f5f5f5',
        border: '1px solid #e3e3e3',
        borderRadius: 5
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    formControl: {
    },
    buttonSubmit: {
        backgroundColor: '#428bca',
        margin: theme.spacing(3, 0, 2),
    },
    inputLabel: {
        color: '#2c3e50',
        display: 'inline-block',
        margin: theme.spacing(2, 0, 1),
        fontWeight: 700
    },
    cueText: {
        'font-size': 11,
        'color': '#808080',
        width: '98%',
    },
    cueTextDanger: {
        'font-size': 11,
        'color': 'red',
        width: '98%',
    }
});

export default styles;
import React from "react";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import EnhancedTable from "../../Shared/EnhancedTable/EnhancedTable";
import { tableStyles } from "../CaseDashboard.style";
import { openDicomViewer } from "../../../Services/DicomService";
import moment from 'moment';

const headCells = [
  { id: "locationName", label: "Location Name", width: "20%" },
  { id: "locationAddress", label: "Address", width: "20%" },
  { id: "studyDescription", label: "Study Description", width: "8%" },
  { id: "studyDate", label: "Study Date", width: "8%", isDate: true, dateOptions: { isUTC: true, convertUTCToLocalTimeZone: false } },
  { id: "uploadDate", label: "Upload Date", width: "8%", isDate: true },
  { id: "sharedBy", label: "Shared By", width: "8%" },
  {
    id: "expirationDate",
    label: "Expiration Date",
    width: "8%",
    isDate: true,
  },
  {
    id: "lastReviewedDate",
    label: "Last Reviewed",
    width: "8%",
    isDate: true,
  },
  { id: "records", label: "Records", width: "12%" },
];

const DicomStudies = (props) => {
  const { classes, studies = [], isLoading } = props;

  // action buttons
  function ViewButton(props) {
    const { classes, row, setRow } = props;

    const onViewButtonClick = async () => {
      const orgLastReviewedValue = row["lastReviewedDate"];

      try {
        const studyNo = row["studyNo"];
        const dicomViewerWindow = openDicomViewer(studyNo);

        if (!dicomViewerWindow.isOpen) {
          console.error(`Failed to open ${dicomViewerWindow.windowName}`);
        }

        row["lastReviewedDate"] = moment();
        setRow(row);
      } catch (err) {
        row["lastReviewedDate"] = orgLastReviewedValue;
        setRow(row);
        console.error(err);
      }
    };

    return (
      <Button
        data-testid="caseRecordViewButton"
        variant="contained"
        size="small"
        className={classes.viewBtn}
        onClick={onViewButtonClick}
      >
      View
      </Button>
    );
  }

  const customCells = {
    records: <ViewButton classes={classes} />,
  };

  return (
    <div>
      <Paper elevation={0} className={classes.tableTitle}>
        Dicom Studies
      </Paper>
      <EnhancedTable
        rows={studies}
        headCells={headCells}
        customCells={customCells}
        rowsPerPageOptions={[]}
        showPagination
        pageCount={5}
        isLoading={isLoading}
      />
    </div>
  );
};

export default withStyles(tableStyles)(DicomStudies);

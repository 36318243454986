import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './CaseInfo.style';
import BootstrapInput from '../../Shared/BootstrapInput/BootstrapInput';


const CaseInfo = (props) => {

    const { classes, expertCaseInfoObj={} } = props;
    return (
        <div>
            <Paper elevation={0} className={classes.titlePaper}>
                <h4 className={classes.title}>Shared Documents</h4>
            </Paper>
            <Paper elevation={0} className={classes.searchPaper}>
                <Grid container spacing={3}>
                    <Grid item xs={6} md={2}>
                        <InputLabel className={classes.inputLabel}>
                            Order Number
						</InputLabel>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormControl
                            className={[
                                classes.formControl,
                                classes.margin,
                                classes.textInput
                            ].join(' ')}
                        >
                            <BootstrapInput id="orderNo" disabled value={expertCaseInfoObj.orderNo || ''} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <InputLabel className={classes.inputLabel}>
                            Claim Number
						</InputLabel>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormControl
                            className={[
                                classes.formControl,
                                classes.margin,
                                classes.textInput
                            ].join(' ')}
                        >
                            <BootstrapInput id="claimNo" disabled value={expertCaseInfoObj.claimNo || ''} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <InputLabel className={classes.inputLabel}>
                            Records Of
						</InputLabel>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormControl
                            className={[
                                classes.formControl,
                                classes.margin,
                                classes.textInput
                            ].join(' ')}
                        >
                            <BootstrapInput id="recordsOf" disabled value={expertCaseInfoObj.recordsOf || ''} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <InputLabel className={classes.inputLabel}>
                            Firm File Number
						</InputLabel>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormControl
                            className={[
                                classes.formControl,
                                classes.margin,
                                classes.textInput
                            ].join(' ')}
                        >
                            <BootstrapInput id="firmFileNo" disabled value={expertCaseInfoObj.firmFileNo || ''} />
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );

};

export default withStyles(styles)(CaseInfo);

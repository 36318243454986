const styles = theme => ({
    title: {
        fontSize: 19,
        marginTop: 20,
        marginBottom: 20,
        fontFamily: 'Lato,"Helvetica Neue",Helvetica,Arial,sans-serif',
        fontWeight: 500,
        lineHeight: 1.1
    },
    titlePaper: {
        width: '100%',
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(1)
    },
    searchPaper: {
        padding: theme.spacing(1),
    },
    // inputs
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    margin: {
        margin: theme.spacing(1)
    },
    inputLabel: {
        marginLeft: theme.spacing(1),
        color: 'inherit',
        display: 'inline-block',
        marginRight: '10px',
        fontWeight: 700,
        lineHeight: 3.3,
        width: '100%',
        textAlign: 'right'
    },
    textInput: {
        width: '100%',
    }
});

export default styles;
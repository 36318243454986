import React from 'react';
import ReactSelect from 'react-select';

const SelectInput = props => {
	const {
		value,
		handleChange,
		options = [],
		classes,
		error,
		...rest
	} = props;

	const styles = {
		container: provided => ({ ...provided, boxShadow: 'none' }),
		control: provided => ({
			...provided,
			boxShadow: 'none',
			borderRadius: 4,
			border: error ? '2px solid red' : '2px solid #ced4da',
			fontSize: 15,
			color: '#2c3e50',
			backgroundColor: '#fff',
			transition: 'border-color 0.3s',
			fontFamily: [
				'Lato',
				'Helvetica Neue',
				'Helvetica',
				'Arial',
				'sans-serif'
			].join(','),
			'&:hover': {
				borderColor: error ? 'red' : '#ced4da'
			},
			'&:focus-within': {
				borderColor: error ? 'red' : '#1abc9c'
			},
			textAlign: 'left'
		}),
		option: provided => ({ ...provided, textAlign: 'left' })
	};

	const filterOption = (option, inputValue) => {
		return option.label.toLowerCase().startsWith(inputValue);
	};

	const getSelectedValue = value => {
		if(value) {
			return options.find(opt => opt.value === value) || {};
		}
	};

	return (
		<ReactSelect
			{...rest}
			isClearable
			options={options}
			value={getSelectedValue(value)}
			onChange={handleChange}
			styles={styles}
			filterOption={filterOption}
		/>
	);
};

export default SelectInput;

import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';

const styles = theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3)
		},
		borderRadius: 4,
		position: 'relative',
		border: '2px solid #ced4da',
		fontSize: 15,
		color: '#2c3e50',
		padding: '2px 10px',
		backgroundColor: '#fff',
		transition: theme.transitions.create(['border-color']),
		fontFamily: [
			'Lato',
			'Helvetica Neue',
			'Helvetica',
			'Arial',
			'sans-serif'
		].join(',')
	},
	error: {
		borderColor: 'Red',
		'&:focus-within': {
			borderColor: 'Red'
		}
	},
	disabled: {
		backgroundColor: '#ecf0f1',
		cursor: 'not-allowed',
		color: '#2c3e50'
	},
	focused: {
		borderColor: '#1abc9c'
	}
});

const BootstrapInput = props => {
	const {
		classes,
		maxLength,
		minLength,
		mask,
		maskChar,
		id,
		dataTestid,
		inputProps,
		...rest
	} = props;
	return mask ? (
		<InputMask
			mask={mask}
			maskChar={maskChar}
			classes={classes}
			inputProps={inputProps}
			{...rest}
		>
			{({ classes, inputProps, ...rest }) => (
				<InputBase
					id={id}
					classes={classes}
					inputProps={{ 'data-testid': dataTestid || id, ...inputProps }}
					{...rest}
				/>
			)}
		</InputMask>
	) : (
		<InputBase
			id={id}
			classes={classes}
			inputProps={{
				maxLength,
				minLength,
				'data-testid': dataTestid || id,
				...inputProps
			}}
			{...rest}
		/>
	);
};

export default withStyles(styles)(BootstrapInput);

const settings = window.APP_SETTINGS;

const getExpertDicomJsonApiEndPointUrl = (studyNo) =>
  `${settings.MYO_API_BASE_URL}/dicom/expertstudyjson/${studyNo}`;

export const openDicomViewer = (studyNo) => {
  const dicomViewerUrl = `${
    settings.DICOM_VIEWER_URL
  }/viewer?url=${getExpertDicomJsonApiEndPointUrl(studyNo)}`;

  const windowName = `DicomViewerWindow-${studyNo}`;
  const windowObject = window.open(dicomViewerUrl, windowName);
  const isOpen = !!windowObject;

  if (isOpen) {
    windowObject.focus();
  }

  return {
    isOpen,
    windowObject,
    windowName,
    studyNo,
  };
};

const styles = theme => ({
    modalContainerBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'black',
        opacity: '.5'
    },
    containerTopSection: {
        backgroundColor: 'white',
        height: '80px;'
    },
    modalTitle: {
        fontSize: '26px;',
        color: '#2c3e50'
    },
    forgotPasswordPaper: {
        padding: theme.spacing(2, 2),
    },
    infoContainer: {
        backgroundColor: '#3498db',
        marginBottom: '20px',
        borderRadius: '4px',
        color: '#fff',
        fontSize: '15px',
        paddingBottom: '20px'
    },
    inputContainer: {
        backgroundColor: '#ecf0f1',
        padding: '20px 10px',
        borderRadius: '4px',
        marginBottom: '20px',
    },
    // inputs
    inputLabel: {
        display: 'inline-block',
        fontWeight: 700,
        lineHeight: 3.3,
        textAlign: 'right',
        width: '100%',
        fontSize: '15px',
        color: '#2c3e50'
    },
    formControl: {
        display: 'inline-block',
        margin: theme.spacing(1, 1),
        width: '100%'
    },
    // Buttons
    modalButton: {
        backgroundColor: '#428bca',
        width: '100px',
        float: 'right'
    },
    submitButton: {
        backgroundColor: '#13987e',
        '&:hover': {
            backgroundColor: '#13987e'
        }
    },
    clearButton: {
        backgroundColor: '#f39c12',
        marginLeft: '8px',
        '&:hover': {
            backgroundColor: '#d2850b',
            borderColor: '#be780a'
        }
    },
    closeButton: {
        backgroundColor: '#f39c12',
        '&:hover': {
            backgroundColor: '#d2850b',
            borderColor: '#be780a'
        }
    },
    // Alerts
    errorAlert: {
        textAlign: 'center',
        backgroundColor: '#e74c3c',
        fontSize: '15px'
    },
    successAlert: {
        backgroundColor: '#18bc9c',
    },
    alertCenteredText: {
        width: '510px',
        marginRight: '-50px'
    },
    // Common
    hidden: {
        display: 'none'
    },
    // validation errors
    cueTextDanger: {
        'font-size': 11,
        'color': 'red',
        width: '98%',
    }
});

export default styles;
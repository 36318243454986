const styles = theme => ({
	title: {
		fontSize: 19,
		marginTop: 20,
		color: '#2c3e50',
		marginBottom: 20,
		fontFamily: 'Lato,"Helvetica Neue",Helvetica,Arial,sans-serif',
		fontWeight: 500,
		lineHeight: 1.1
	},
	titlePaper: {
		width: '100%',
		display: 'inline-flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingLeft: theme.spacing(1)
	},
	containerPaper: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		backgroundColor: '#ecf0f1',
		border: '1px solid #e3e3e3'
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(3)
	},
	labelGridItem: {
		textAlign: 'right'
	},
	inputGridItem: {
		textAlign: 'center'
	},
	inputLabel: {
		color: '#2c3e50',
		display: 'inline-block',
        fontWeight: 700,
        fontSize: 14
	},
	errorHint: {
		fontSize: '11px',
        color: 'red'
    },
    cueText: {
		fontSize: '11px',
        color: '#808080'
	},
	cueTextDanger: {
		color: 'red'
	},
	actions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	clearButton: {
        backgroundColor: '#cccccc',
        margin: theme.spacing(1, 0, 0, 0),
		float: 'left',
		width: 80,
        '&:hover': {
            backgroundColor: '#bcbbbb'
        }
	},
	submitButton: {
        backgroundColor: '#06b08f',
        color: '#fff',
        margin: theme.spacing(1, 0, 0, 0),
		float: 'right',
		width: 80,
        '&:hover': {
            backgroundColor: '#059f81'
        }
	},
	errorMsg: {
		padding: theme.spacing(2),
		backgroundColor: '#f2dede',
		border: '1px solid #ebccd1',
		boxShadow: 'none',
		color: '#a94442'
	}
});

export default styles;

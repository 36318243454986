import React from 'react';
import EnhancedTable from '../../Shared/EnhancedTable/EnhancedTable';
import styles from './CaseManagerGrid.style';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const CaseManagerGrid = props => {
	const { classes, fetchRowsPromise } = props;

	// link component
	function OrderNoLink(props) {
		const { classes, row } = props;

		return (
			<Link className={classes.orderLink} to={`/cases/${row['orderNo']}`}>
				{row['orderNo']}
			</Link>
		);
	}

	const customCells = {
		// the name of the custom cell must be equal to the column id
		orderNo: <OrderNoLink classes={classes} />
	};

	const headCells = [
		{ id: 'orderNo', label: 'Order No.', width: '12%' },
		{ id: 'office', label: 'Office', width: '20%' },
		{ id: 'contact', label: 'Contact', width: '12%' },
		{ id: 'recordsOf', label: 'Records Of', width: '12%' },
		{ id: 'caseName', label: 'Case Name', width: '20%' },
		{ id: 'claimNo', label: 'Claim No.', width: '12%' },
		{ id: 'firmFileNo', label: 'Firm File No.', width: '12%' }
	];
	return (
		<EnhancedTable
			headCells={headCells}
			rowsPerPageOptions={[]}
			useDbPagination={true}
			customCells={customCells}
			showPagination
			fetchRowsPromise={fetchRowsPromise}
		/>
	);
};

export default withStyles(styles)(CaseManagerGrid);

import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root: {
		margin: 20,
		padding: 20
	},
	paper: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	}
});

const Error = props => {
	const {classes, errorMessage: propErrMsg} = props;
	const errorMessage = propErrMsg || props.match.params.error;
	console.error(errorMessage);
	return (
		<div className={classes.root}>
			<Paper className={classes.paper} elevation={1} square={true}>
				{errorMessage || 'Oops. There was an error processing this request. Please try again.'}
			</Paper>
		</div>
	);
};

export default withStyles(styles)(Error);

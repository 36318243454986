import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PersonIcon from '@material-ui/icons/Person';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { logout } from '../../../Services/AuthService';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { NavLink } from 'react-router-dom';

const appBarHeight = 100;

const styles = theme => ({
    // appBar
    appBar: {
        height: appBarHeight,
        paddingTop: '20px',
        backgroundColor: '#575757',
        borderBottom: 'solid #FF8200 3px',
        boxShadow: 'none'
    },
    appBarToolBar: {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    personIcon: {
        color: 'white'
    },
    arrowDropDownIcon: {
        color: 'white'
    }
});

function NavBar(props) {
    const { classes } = props;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const prevOpen = React.useRef(open);

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleLogout = () => {
        logout('/');
    };
    
    return (
        <AppBar position='fixed' className={classes.appBar} color='primary'>
            <Toolbar className={classes.appBarToolBar}>
                <NavLink data-testid='myOLogo' to='/'>
                    <img
                        src='/images/MyOntellus-Logo-Portal-2_WhiteText.png'
                        height='55px'
                        alt='Ontellus Expert Portal'
                        title='Ontellus Expert Portal'
                    ></img>
                </NavLink>
                <div>
                    <Button
                        data-testid='handleToggle'
                        ref={anchorRef}
                        onClick={handleToggle}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup='true'
                    >
                        <PersonIcon className={classes.personIcon} />
                        <ArrowDropDownIcon
                            className={classes.arrowDropDownIcon}
                        />
                    </Button>

                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom'
                                            ? 'center top'
                                            : 'center bottom'
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener
                                        onClickAway={handleClose}
                                    >
                                        <MenuList
                                            autoFocusItem={open}
                                            id='menu-list-grow'
                                        >
                                            <MenuItem data-testid='logout' onClick={handleLogout}>
                                                <ListItemIcon>
                                                    <ExitToAppIcon></ExitToAppIcon>
                                                </ListItemIcon>
                                                Logout
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </Toolbar>
        </AppBar>
    );
}

export default withStyles(styles)(NavBar);

import axios from 'axios';

const settings = window.APP_SETTINGS;

export const searchCases = searchParams => {
	return axios.get(`${settings.MYO_API_BASE_URL}/cases/expertSearch`, {
		params: {
			...searchParams
		}
	});
};

export const getExpertCase = id => {
	return axios.get(`${settings.MYO_API_BASE_URL}/cases/experts/${id}`);
};

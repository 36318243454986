import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import styles from './RecordsDocumentsDialog.style';
import {DialogContent, DialogTitle, Typography, Box, Grid, CircularProgress} from '@material-ui/core';
import EnhancedTable from '../../../Shared/EnhancedTable/EnhancedTable';
import { getExpertRecordDocuments } from '../../../../Services/DepositoryService';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {handleDownloadFile, openPdfFileInNewWindow } from '../../../../Services/FileService';
import isPdf from '../../../../Utilities/isPdf';

const headCells = [
    { id: 'fileName', label: 'Records', width: '25%' },
    { id: 'fileType', label: 'Document Type', width: '25%' },
    { id: 'publishDate', label: 'Publish Date', width: '12%',isDate: true  },
    { id: 'reviewDate', label: 'Review Date', width: '12%', isDate: true },
    { id: 'actions', label: 'Actions', width: '30%' }
  ];

const RecordsDocumentsDialog = props => {
    const [isViewingFile, setIsViewingFile] = useState(false);
    const [loading ,setLoading] = useState(false);
    const [documents ,setDocuments] = useState([]);

    const {
        classes,
        title,
        id,
        open,
        setOpen,
        recordsInfo,
        canDownloadFiles
    } = props;

    const handleGetExpertRecordDocuments = useCallback(async ()=>{
        if(open && recordsInfo)
        {
            try{
                setLoading(true);
                const response = await getExpertRecordDocuments(recordsInfo['trackingNo']);
                setDocuments(response.data);
                setLoading(false);}

            catch(e){
                console.error(e);
                setLoading(false);}
        }
    },[recordsInfo, open]);
      

    useEffect(()=>{
        handleGetExpertRecordDocuments();
    },[handleGetExpertRecordDocuments]);
    
    const customCells = {
        actions: <ViewButton classes={classes} canDownloadFiles={canDownloadFiles} />
    };

    // action buttons
  function ViewButton(props) {
    const { classes, row, setRow, canDownloadFiles} = props;
    const [btnDownloading, setBtnDownloading] = useState(false);
    const [completedDownload, setCompletedDownload] = useState(0);
    const [variantDownload, setVariantDownload] = useState("indeterminate");
    const [isPdfFile, setIsPdfFile] = useState(false);
    const [disableDownloadButton, setDisableDownloadButton] = useState(false); 
    const fileName = row? row['fileName']:"";

    console.debug('Record Document:outside of useEffect, fileName:', fileName, 'isPdf:', isPdfFile, 'canDownloadFiles:', canDownloadFiles, 'disableDownloadButton:', disableDownloadButton, 'row:', row);

    useEffect(() => {
      const PdfFile = isPdf(fileName);
      const disableDownloadButton = !canDownloadFiles && PdfFile;
  
      setIsPdfFile(PdfFile);
      setDisableDownloadButton(disableDownloadButton);
      console.debug('Record Document:inside of useEffect, fileName:', fileName, 'isPdf:', PdfFile, 'canDownloadFiles:', canDownloadFiles, 'disableDownloadButton:', disableDownloadButton, 'row:', row);


    }, [fileName, canDownloadFiles]);

    const onDownloadButtonClick = async () => {
      if (disableDownloadButton) return;

      await handleDownloadFile(
        btnDownloading, 
        setBtnDownloading, 
        setVariantDownload, 
        setCompletedDownload, 
        row,
        "reviewDate",
        "dpstNo",
        setRow,
        recordsInfo.caseNo,
        recordsInfo['trackingNo']
        );
    };

    const onOpenButtonClick = async () => {
      await openPdfFileInNewWindow(
        row,
        "dpstNo",
        recordsInfo.caseNo,
        'reviewDate',
        setRow,
        setIsViewingFile,
        recordsInfo['trackingNo']
        );
    };

    return (
      <div className={classes.viewSection}>
        <Button
          data-testid="caseRecordViewButton"
          variant="contained"
          size="small"
          className={classes.viewBtn}
          onClick={onOpenButtonClick}
          disabled={!isPdfFile}
        >
          {
            isViewingFile 
              ? (<CircularProgress
                  color="inherit"
                  size={24}
                  thickness={8}
                />) 
              : 'View'
          }
        </Button>
        <Button
          data-testid="caseRecordDownloadButton"
          variant="contained"
          size="small"
          className={classes.viewBtn}
          disabled={!row['fileName'] || disableDownloadButton}
          onClick={onDownloadButtonClick}
        >
          {btnDownloading ? (
            <CircularProgress
              color="inherit"
              size={24}
              thickness={8}
              variant={variantDownload}
              value={completedDownload}
            />
          ) : (
            'Download'
          )}
        </Button>
      </div>
    );
  }
    
    const handleClose =()=>{
        setOpen(false);
        setDocuments([]);
    }
    return (
        <Dialog
            open={open}
            id={id}
            onClose={handleClose}
            aria-labelledby="record-documents-dialog"
            classes={{ paper: classes.dialogPaper }}
            maxWidth="md">   
            <DialogTitle id="record-documents-dialog" className={classes.dialogHeader}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    {title}
                    <IconButton aria-label="close"          
                                data-testid="close-button"
                                onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.dialogBody}>
            {recordsInfo?.headerData && 
                <Box style={{marginBottom:"10px"}}>
                {recordsInfo?.headerData?.map((p,i)=>{
                const rowClass =(i + 1) % 2 === 0 ? classes.even : classes.odd;
                return <Box className={rowClass} key={p.label}>
                        <Typography style={{ padding: 10, fontSize:"16px" }}>
                            <Typography  component="span" className={classes.headerTitle}>
                                {p.label}:
                            </Typography>   
                            {p.value}
                        </Typography>
                        </Box> 
                        })
                }
                </Box>
            }    
            <EnhancedTable
                    rows={documents}
                    headCells={headCells}
                    customCells={customCells}
                    rowsPerPageOptions={[]}
                    showPagination
                    pageCount={5}
                    isLoading={loading}
                />          
            </DialogContent>           
        </Dialog>
    );
};

export default withStyles(styles)(RecordsDocumentsDialog);

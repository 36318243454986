import axios from 'axios';

const settings = window.APP_SETTINGS;

export const getExpert = () => {
	return axios.get(`${settings.MYO_API_BASE_URL}/users/expert`, {
		transformResponse: strData => {
			if (!strData) return;
			const data = JSON.parse(strData);
			return {
				email: data.emailAddress,
				firstName: data.firstName,
				lastName: data.lastName,
				company: data.company,
				address: data.streetAddress,
				city: data.city,
				state: data.stateID,
				zip: data.zipCode,
				phone: data.phoneNumber,
				fax: data.faxNumber
			};
		}
	});
};

export const updateExpert = values => {
	const updates = {
		firstName: values.firstName,
		lastName: values.lastName,
		company: values.company,
		street: values.address,
		city: values.city,
		stateID: values.state,
		zip: values.zip,
		phone: values.phone,
		fax: values.fax
	};

	if (values.password) {
		updates.changePassword = {
			password: values.password,
			confirmPassword: values.confirmPassword
		}
	}
	
	return axios.put(`${settings.MYO_API_BASE_URL}/users/expert`, updates);
};

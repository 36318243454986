import axios from 'axios';

const settings = window.APP_SETTINGS;


export const downloadSmartChronologyFile = (chronRequestId, onDownloadProgress) => {
	return axios.get(`${settings.MYO_API_BASE_URL}/sharedocuments/download/smartchron/${chronRequestId}`,{responseType: "blob",onDownloadProgress});
};

export const getUrlSmartChronologyFile = (chronRequestId) => {
	return axios.get(`${settings.MYO_API_BASE_URL}/sharedocuments/view/smartchron/${chronRequestId}`);
};

export const checkChronologyFileExist = (chronRequestId) => {
	return axios.get(`${settings.MYO_API_BASE_URL}/sharedocuments/smartchron/${chronRequestId}/check`);
};
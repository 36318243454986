const styles = theme => ({
    root: {
        flexGrow: 1
    },
    title: {
        fontSize: 19,
        marginTop: 20,
        color: '#2c3e50',
        marginBottom: 20,
        fontFamily: 'Lato,"Helvetica Neue",Helvetica,Arial,sans-serif',
        fontWeight: 500,
        lineHeight: 1.1
    },
    titlePaper: {
        width: '100%',
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(1)
    }
});

export default styles;
const styles = theme => ({
	root: {
		backgroundImage: 'url(./images/testimonial-woman-.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
		padding: theme.spacing(10, 0),
		display: 'flex',
		flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100%'
	},
	boxPaper: {
		padding: theme.spacing(4),
		margin: theme.spacing(2, 0),
		background: '#f5f5f5',
		border: '1px solid #e3e3e3',
		borderRadius: 5
	},
	gridItem: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(3)
	},
	buttonSubmit: {
		backgroundColor: '#428bca',
		margin: theme.spacing(3, 0, 2)
	},
	inputLabel: {
		color: '#2c3e50',
		display: 'inline-block',
		margin: theme.spacing(2, 0, 1),
		fontWeight: 700
	},
	errorHint: {
		fontSize: '11px',
        color: 'red',
        maxWidth: '310px'
	},
	cueText: {
		fontSize: '11px',
        color: '#808080',
        maxWidth: '310px'
	},
	cueTextDanger: {
		color: 'red'
	},
	errorMsg: {
        padding: theme.spacing(2),
        backgroundColor: '#f2dede',
        border: '1px solid #ebccd1',
        boxShadow: 'none',
		color: '#a94442'
	},
});

export default styles;

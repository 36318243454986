import axios from 'axios';

const settings = window.APP_SETTINGS;

export const validateInvitationToken = token => {
    return axios.post(
        `${settings.MYO_API_BASE_URL}/users/ValidateInvitationToken`,
        { token }
    );
};

export const validateResetToken = token => {
    return axios.post(
        `${settings.MYO_API_BASE_URL}/users/ValidateResetPasswordToken`,
        { token }
    );
};

export const createUser = (
    userName,
    password,
    confirmPassword,
    token
) => {
    const userCredentials = {
        email: userName,
        password,
        confirmPassword,
        invitationToken: token
    };

    return axios.post(`${settings.MYO_API_BASE_URL}/users`, userCredentials);
};

export const sendResetPasswordEmail = email => {
    return axios.put(`${settings.MYO_API_BASE_URL}/users/password`, {
        email
    });
};

export const resetPassword = (password, confirmPassword, token) => {
    const params = {
        password,
        confirmPassword,
        token
    };

    return axios.post(`${settings.MYO_API_BASE_URL}/users/password`, params);
};

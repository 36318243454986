import OutlineItem from './OutlineItem';
import Ref from './ref';

const Outline = ({ pdf, outline, onItemClick }) => {
  const onOutlineItemClick = async (dest) => {
    const destinationValue =
      typeof dest === 'string' ? await pdf.getDestination(dest) : dest;

    if (!destinationValue) {
      throw new Error('Destination not found.');
    }

    const [ref] = destinationValue;

    const pageIndex = await pdf.getPageIndex(new Ref(ref));

    onItemClick({ pageIndex});
  };

  return outline.map((i, index) => (
    <OutlineItem
      key={index}
      outlineItem={i}
      onOutlineItemClick={onOutlineItemClick}
    />
  ));
};

export default Outline;

import React from 'react';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const styles = theme => ({
	root: {
		margin: 20,
		padding: 20
	},
	paper: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	}
});

const TokenFetchError = (props) => {
    const {classes} = props;
    
    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={1} square={true}>
                We were unable to renew your session. <Link data-testid='clickHereLink' to="/">Click here</Link> log in again.
            </Paper>
        </div>
    );
}

export default withStyles(styles)(TokenFetchError);


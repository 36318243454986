const styles = theme => ({
    dialogPaper: {
        width: '100%',
        fontFamily: 'Lato,"Helvetica Neue",Helvetica,Arial,sans-serif',
    },
    dialogHeader: {
        minHeight: '16.43',
        padding: 15,
        fontWeight: 700,
        fontSize: 20,
        textTransform: 'uppercase',
        borderBottom: '1px solid #e5e5e5'
    },
    dialogBody: {
        padding: 20,
    },
    dialogFooter: {
        minHeight: '16.43',
        padding: 15,
        borderTop: '1px solid #e5e5e5'
    },
	okButton: {
        backgroundColor: '#06b08f',
        borderColor: '#06b08f',
        color: '#fff',
        margin: theme.spacing(1, 0, 0, 0),
		float: 'right',
        '&:hover': {
            backgroundColor: '#059f81'
        }
    },
    cancelButton: {
        backgroundColor: '#f39c12',
        borderColor: '#f39c12',
        color: '#fff',
        margin: theme.spacing(1, 0, 0, 0),
		float: 'left',
        '&:hover': {
            backgroundColor: '#d2850b',
            borderColor: '#be780a'
        }
	},
});

export default styles;
import React, { useState, useRef, memo } from 'react';
import { Thumbnail } from 'react-pdf';
import Outline from './Outline';
import styles from './Pdf.module.css';
import classNames from 'classnames';
import PdfDocument from './PdfDocument';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, areEqual } from 'react-window';

const renderThumbnail = memo(({ data, index, style }) => {
  const { pdf, scrollToPage } = data;
  return (
    <div style={style}>
      <Thumbnail
        pdf={pdf}
        pageNumber={index + 1}
        width={98}
        height={126}
        onItemClick={scrollToPage}
      />
    </div>
  );
}, areEqual);

const Pdf = (props) => {
  const { pdfFile } = props;
  const [pdf, setPdf] = useState(null);
  const [outline, setOutline] = useState([]);
  const [outlineHasDeepNesting, setOutlineHasDeepNesting] = useState(false);
  const [isThumbnailShown, setIsThumbnailShown] = useState(true);
  const documentRef = useRef(null);

  const onDocumentLoad = async (pdf) => {
    const outline = (await pdf.getOutline()) ?? [];
    setOutlineHasDeepNesting(
      outline.some((i) => i.items && i.items.length > 0)
    );

    setOutline(outline);
    setIsThumbnailShown(outline.length === 0);
    setPdf(pdf);
  };

  const scrollToPage = ({ pageIndex }) => {
    documentRef.current?.scrollToPage(pageIndex);
  };

  return (
    <div
      id={styles.outerContainer}
      className={classNames(styles.sidebarOpen)}
      tabIndex={1}
    >
      <div id={styles.sidebarContainer}>
        <div id={styles.toolbarSidebar}>
          <div id={styles.toolbarSidebarLeft}>
            <div
              id={styles.sidebarViewButtons}
              className={`${styles.splitToolbarButton}`}
              role="radiogroup"
            >
              <button
                id={styles.viewThumbnail}
                className={classNames(styles.toolbarButton, {
                  [styles.toggled]: isThumbnailShown,
                })}
                title="Show Thumbnails"
                tabIndex={2}
                role="radio"
                aria-checked="true"
                aria-controls="thumbnailView"
                onClick={() => setIsThumbnailShown(true)}
              >
                <span>Thumbnails</span>
              </button>
              <button
                id={styles.viewOutline}
                className={classNames(styles.toolbarButton, {
                  [styles.toggled]: !isThumbnailShown,
                })}
                title="Show Document Outline (double-click to expand/collapse all items)"
                tabIndex="3"
                role="radio"
                aria-checked="false"
                aria-controls="outlineView"
                disabled={outline.length === 0}
                onClick={() => setIsThumbnailShown(false)}
              >
                <span>Document Outline</span>
              </button>
            </div>
          </div>
        </div>
        <div id={styles.sidebarContent}>
          {pdf && (
            <div style={{ height: '100%', width: '100%' }}>
              <AutoSizer
                className={classNames({ [styles.hidden]: !isThumbnailShown })}
              >
                {({ height, width }) => (
                  <List
                    height={height}
                    width={width}
                    itemSize={140.8}
                    itemCount={pdf.numPages}
                    itemData={{ pdf, scrollToPage }}
                  >
                    {renderThumbnail}
                  </List>
                )}
              </AutoSizer>
            </div>
          )}
          <div
            id={styles.outlineView}
            className={classNames({
              [styles.hidden]: isThumbnailShown,
              [styles.treeWithDeepNesting]: outlineHasDeepNesting,
            })}
          >
            {pdf && (
              <Outline pdf={pdf} outline={outline} onItemClick={scrollToPage} />
            )}
          </div>
        </div>
      </div>

      <div id={styles.mainContainer}>
        <div id={styles.viewerContainer} tabIndex="0">
          <PdfDocument
            pdfFile={pdfFile}
            onDocumentLoad={onDocumentLoad}
            scrollToPage={scrollToPage}
            ref={documentRef}
          />
        </div>
      </div>
    </div>
  );
};

export default Pdf;

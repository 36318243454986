import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withStyles } from "@material-ui/core/styles";
import ViewIcon from "@material-ui/icons/Visibility";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import {
  checkChronologyFileExist,
  downloadSmartChronologyFile,
  getUrlSmartChronologyFile,
} from "../../../../Services/DocumentAccessService";
import loader from '../../../../assets/loading.gif';

const styles = (theme) => ({
  arrowDropDownIcon: {
    color: "white",
  },
  Button: {
    backgroundColor: "#f39c12",
    color: "white",
    textTransform: "none",
    with: "200px",
    "&:hover": {
      backgroundColor: "#d2850b",
      borderColor: "#be780a",
    },
  },
  menuTitle: {
    paddingRight: 10,
    borderRight: "2px solid white",
  },
  menuItem: {
    color: "#f39c12",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  notReady: {
    color: "#dbd8d4",
    "&:hover": {
      backgroundColor: "#fff",
    },
    cursor: "default",
  },
});

const RecordChronologyMenu = (props) => {
  const {
    classes,
    chronFileRequestId,
    setChronologyFileUploaded,
    setChronologyFileData,
    chronologyFileData,
    chronologyFileUploaded,
    canDownloadFiles
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const [checkingFile, setCheckingFile] = useState(false);

  const [btnOpening, setBtnOpening] = useState(false);

  const [btnDownloading, setBtnDownloading] = useState(false);
  const [completedDownload, setCompletedDownload] = useState(0);
  const [variantDownload, setVariantDownload] = useState("indeterminate");

  const handleToggle = async (event) => {
      if(checkingFile) return;
      setAnchorEl(event.currentTarget);
      if(!chronologyFileUploaded)
      {
          try
          {
              setCheckingFile(true);
              const result = await checkChronologyFileExist(chronFileRequestId);
              setChronologyFileUploaded(result.data);
              setCheckingFile(false);
          }catch(e){
              setChronologyFileUploaded(false);
              setCheckingFile(false);
          }
      }

      setOpen(!open);
  };

  const handleDropClose = () => {
      setAnchorEl(null);
      setOpen(false);
  };

  const downloadSmartChronology = async (setVariant, setCompleted) => {
    if (!chronologyFileData) {
      const response = await downloadSmartChronologyFile(chronFileRequestId, (e) => {
        setVariant("determinate");
        setCompleted(Math.floor((e.loaded / e.total) * 100));
      });
      const filename = response.headers["x-file-name"];

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      setChronologyFileData({ url, filename });
      return { url, filename };
    }
   return chronologyFileData;
  };

  const ViewSmartChronology = async () => {
      const response = await getUrlSmartChronologyFile(chronFileRequestId);  
      return { sasURl: response.data };
    };

  const handleDownloadFile = async () => {
    try {
      setBtnDownloading(true);
      const response = await downloadSmartChronology(
        setVariantDownload,
        setCompletedDownload
      );
      const url = response.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setBtnDownloading(false);
    } catch (e) {
      console.error(e);
      setBtnDownloading(false);
      setVariantDownload("indeterminate");
    }
  };

  const handleViewFile = async () => {
    try {
      let newWin = window.open("", "_blank");
      newWin.document.body.background = loader;
      newWin.document.body.style.backgroundRepeat = "no-repeat";
      newWin.document.body.style.backgroundPosition = "center";
      setBtnOpening(true);

      const response = await ViewSmartChronology();

      newWin.location.href = `/pdf-viewer?pdf=${encodeURIComponent(response.sasURl)}`;

      setBtnOpening(false);
    } catch (e) {
      console.error(e);
      setBtnOpening(false);
    }
  };

  return (
    <div>
      <Button
        data-testid="handleToggle"
        onClick={handleToggle}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        className={classes.Button}
      >
        <Typography className={classes.menuTitle}>Record Chronology</Typography>
        {checkingFile ? (
          <CircularProgress color="inherit" size={16} thickness={6} />
        ) : (
          <ArrowDropDownIcon className={classes.arrowDropDownIcon} />
        )}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleDropClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        anchorReference="anchorEl"
        PaperProps={{
          style: {
            width: "200px",
            border: "1px solid #f9f9f9",
          },
        }}
      >
        {!chronologyFileUploaded ? (
          <MenuItem className={classes.notReady}>
            File is not ready yet..
          </MenuItem>
        ) : (
          [
            <MenuItem
              data-testid="handleViewFile"
              onClick={handleViewFile}
              disabled={btnOpening || !chronologyFileUploaded}
              className={classes.menuItem}
              key = "viewFile"
            >
              {btnOpening ? (
                <>
                  <CircularProgress
                    color="inherit"
                    size={24}
                    thickness={8}
                  />
                  preparing...
                </>
              ) : (
                <>
                  <ViewIcon />
                  View
                </>
              )}
            </MenuItem>,
            <MenuItem
              data-testid="handleDownloadFile"
              onClick={handleDownloadFile}
              disabled={!canDownloadFiles || btnDownloading || !chronologyFileUploaded}
              className={classes.menuItem}
              key = "downloadFile"
            >
              {btnDownloading ? (
                <>
                  <CircularProgress
                    color="inherit"
                    size={24}
                    thickness={8}
                    variant={variantDownload}
                    value={completedDownload}
                  />
                  preparing...
                </>
              ) : (
                <>
                  <ArrowDownward />
                  Download
                </>
              )}
            </MenuItem>
        ]
        )}
      </Menu>
    </div>
  );
};

export default withStyles(styles)(RecordChronologyMenu);

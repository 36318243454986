import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import BootstrapInput from '../Shared/BootstrapInput/BootstrapInput';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styles from './CreatePassword.style';
import {
    createUser,
    validateInvitationToken
} from '../../Services/UserService';
import { useLocation, useNavigate } from 'react-router-dom';

const CreatePasswordForm = props => {
    const navigate = useNavigate();
    const location = useLocation();
    const classes = props.classes;
    const [userName, setUserName] = useState('');
    const [renderPage, setRenderPage] = useState(false);
    const [token, setToken] = useState('');
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[<>)(#?!@$%^&*_,-]).{8,}$/;

    const onSubmit = async values => {
        const { userName, password, confirmPassword } = values;
        try {
            await createUser(userName, password, confirmPassword, token);
            navigate('/login');
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const validateToken = async () => {
            try {
                const searchParams = new URLSearchParams(location.search);
                // t is user for email encrypted value (token).
                const tokenParam = searchParams.get('t');

                var validationResult = await validateInvitationToken(
                    tokenParam
                );

                const { expertEmail, isExists } = validationResult.data;

                if (isExists) {
                    navigate('/login');
                }
                setRenderPage(true);
                setUserName(expertEmail);
                setToken(tokenParam);
            } catch (e) {
                console.error(e);
                setRenderPage(false);
                navigate('/invalidlink',
                    {
                        errorMsg:
                            'Invalid create password link or the link has expired.'
                    }
                );
            }
        };

        validateToken();
    }, [location.search]);

    return (
        <React.Fragment>
            {renderPage ? (
                <div className={classes.backgroundPaper}>
                    <Box display='flex' justifyContent='flex-start' m={1} p={1}>
                        <Container component='div' maxWidth='xs'>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    userName: userName,
                                    password: '',
                                    confirmPassword: ''
                                }}
                                onSubmit={onSubmit}
                                validationSchema={Yup.object().shape({
                                    password: Yup.string()
                                        .strict(false)
                                        .trim()
                                        .required()
                                        .matches(passwordRegex),
                                    confirmPassword: Yup.string().when(
                                        'password',
                                        {
                                            is: val =>
                                                val && val.length > 0
                                                    ? true
                                                    : false,
                                            then: Yup.string()
                                                .oneOf(
                                                    [Yup.ref('password')],
                                                    "Passwords don't match."
                                                )
                                                .required(
                                                    'Confirm password field is required.'
                                                )
                                        }
                                    )
                                })}
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isValid
                                    } = props;

                                    return (
                                        <div>
                                            <div className={classes.boxPaper}>
                                                <img
                                                    src='images/Ontellus_logo_CMYK.png'
                                                    height='80px'
                                                    width='210px'
                                                    alt='Ontellus Logo'
                                                />
                                                <form
                                                    className={classes.form}
                                                    noValidate
                                                    autoComplete='off'
                                                    onSubmit={handleSubmit}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        container
                                                        direction='column'
                                                        justify='center'
                                                        alignItems='center'
                                                    >
                                                        <InputLabel
                                                            shrink
                                                            className={
                                                                classes.inputLabel
                                                            }
                                                        >
                                                            Username
                                                        </InputLabel>
                                                        <FormControl fullWidth>
                                                            <BootstrapInput
                                                                id='userName'
                                                                disabled
                                                                value={
                                                                    values.userName
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        container
                                                        direction='column'
                                                        justify='center'
                                                        alignItems='center'
                                                    >
                                                        <InputLabel
                                                            shrink
                                                            className={
                                                                classes.inputLabel
                                                            }
                                                        >
                                                            Create Password
                                                        </InputLabel>
                                                        <FormControl
                                                            required
                                                            fullWidth
                                                            className={
                                                                classes.formControl
                                                            }
                                                        >
                                                            <BootstrapInput
                                                                id='password'
                                                                type='password'
                                                                value={
                                                                    values.password
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                autoComplete='off'
                                                                maxLength='64'
                                                                error={
                                                                    errors.password &&
                                                                    touched.password
                                                                }
                                                            />
                                                        </FormControl>
                                                        <span
                                                            className={
                                                                errors.password &&
                                                                    touched.password
                                                                    ? classes.cueTextDanger
                                                                    : classes.cueText
                                                            }
                                                        >
                                                            Must contain min 8
                                                            characters, 1
                                                            uppercase, 1 digit
                                                            and 1 special
                                                            character.
                                                        </span>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        container
                                                        direction='column'
                                                        justify='center'
                                                        alignItems='center'
                                                    >
                                                        <InputLabel
                                                            shrink
                                                            className={
                                                                classes.inputLabel
                                                            }
                                                        >
                                                            Confirm Password
                                                        </InputLabel>
                                                        <FormControl
                                                            required
                                                            fullWidth
                                                            className={
                                                                classes.formControl
                                                            }
                                                        >
                                                            <BootstrapInput
                                                                id='confirmPassword'
                                                                type='password'
                                                                value={
                                                                    values.confirmPassword
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                autoComplete='off'
                                                                maxLength='64'
                                                                error={
                                                                    errors.confirmPassword &&
                                                                    touched.confirmPassword
                                                                }
                                                            />
                                                        </FormControl>
                                                        {errors.confirmPassword &&
                                                            touched.confirmPassword && (
                                                                <span
                                                                    className={
                                                                        classes.cueTextDanger
                                                                    }
                                                                >
                                                                    {
                                                                        errors.confirmPassword
                                                                    }
                                                                </span>
                                                            )}
                                                    </Grid>
                                                    <Button
                                                        type='submit'
                                                        fullWidth
                                                        variant='contained'
                                                        color='primary'
                                                        className={
                                                            classes.buttonSubmit
                                                        }
                                                        disabled={
                                                            !isValid ||
                                                            isSubmitting
                                                        }
                                                        id="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                </form>
                                            </div>
                                            <div
                                                style={{ textAlign: 'justify' }}
                                            >
                                                PLEASE NOTE: For the optimal
                                                user experience, please update
                                                to the latest version of your
                                                Internet browser. Up-to-date
                                                browsers are faster, provide
                                                enhanced security and allow for
                                                features with increased design
                                                and functionality.
                                            </div>
                                        </div>
                                    );
                                }}
                            </Formik>
                        </Container>
                    </Box>
                </div>
            ) : (
                    <div>Please wait...</div>
                )}
        </React.Fragment>
    );
};

export default withStyles(styles)(CreatePasswordForm);

import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import BootstrapInput from '../Shared/BootstrapInput/BootstrapInput';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styles from './ResetPassword.style';
import {
	resetPassword,
	validateResetToken
} from '../../Services/UserService';
import ResetComplete from './ResetComplete/ResetComplete';
import { Paper } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';

const ResetPassword = props => {
	const navigate = useNavigate();
	const location = useLocation();
	const classes = props.classes;
	const [renderPage, setRenderPage] = useState(false);
	const [resetToken, setResetToken] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [resetComplete, setResetComplete] = useState(false);

	const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[<>)(#?!@$%^&*_,-]).{8,}$/;

	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.strict(false)
			.trim()
			.required()
			.matches(passwordRegex),
		confirmPassword: Yup.string().when('password', {
			is: val => (val && val.length > 0 ? true : false),
			then: Yup.string()
				.oneOf([Yup.ref('password')], "Passwords don't match.")
				.required('Confirm password field is required.')
		})
	});

	const onSubmit = async values => {
		const { password, confirmPassword } = values;
		setIsSubmitting(true);
		try {
			await resetPassword(password, confirmPassword, resetToken);
			setIsSubmitting(false);
			setResetComplete(true);
		} catch (e) {
			setIsSubmitting(false);
			setErrorMsg('An error occured while restting your password.');
			console.error(e);
		}
	};

	useEffect(() => {
		const validateToken = async () => {
			try {
				const searchParams = new URLSearchParams(location.search);
				const token = searchParams.get('token');
				setResetToken(token);
				await validateResetToken(token);
				setRenderPage(true);
			} catch (e) {
				navigate('/invalidlink',
					{
						state: {
							errorMsg: 'Invalid reset password link or the link has expired.'
						}
					});
					return null;
			}
		};

		validateToken();
	}, []);

	return renderPage ? (
		resetComplete ? (
			<ResetComplete />
		) : (
			<div className={classes.root}>
				<Container component='div' maxWidth='xs'>
					<Formik
						initialValues={{
							password: '',
							confirmPassword: ''
						}}
						onSubmit={onSubmit}
						validationSchema={validationSchema}
					>
						{props => {
							const {
								values,
								touched,
								errors,
								handleChange,
								handleBlur,
								handleSubmit,
								isValid
							} = props;

							return (
								<div>
									{errorMsg && (
										<Paper className={classes.errorMsg}>
											<strong>Error: </strong>
											{errorMsg}
										</Paper>
									)}
									<Grid
										container
										direction='column'
										justify='center'
										alignItems='center'
										className={classes.boxPaper}
									>
										<img
											src='./images/MyOntellus_Logo-Portal_GrayText.png'
											height='80px'
											width='210px'
											alt='Ontellus Logo'
										/>
										<form
											className={classes.form}
											noValidate
											autoComplete='off'
											onSubmit={handleSubmit}
										>
											<Grid item xs={12} className={classes.gridItem}>
												<InputLabel shrink className={classes.inputLabel}>
													New Password
												</InputLabel>
												<FormControl
													required
													fullWidth
													className={classes.formControl}
												>
													<BootstrapInput
														id='password'
														type='password'
														autoComplete='off'
														maxLength='64'
														value={values.password}
														onChange={handleChange}
														onBlur={handleBlur}
														error={errors.password && touched.password}
													/>
												</FormControl>
												<span
													className={[
														classes.cueText,
														errors.password &&
														touched.password &&
														classes.cueTextDanger
													].join(' ')}
												>
													Must contain min 8 characters, 1 uppercase, 1 digit
													and 1 special character.
												</span>
											</Grid>
											<Grid item xs={12} className={classes.gridItem}>
												<InputLabel shrink className={classes.inputLabel}>
													Confirm Password
												</InputLabel>
												<FormControl
													required
													fullWidth
													className={classes.formControl}
												>
													<BootstrapInput
														id='confirmPassword'
														type='password'
														autoComplete='off'
														maxLength='64'
														value={values.confirmPassword}
														onChange={handleChange}
														onBlur={handleBlur}
														error={
															errors.confirmPassword && touched.confirmPassword
														}
													/>
												</FormControl>
												{errors.confirmPassword && touched.confirmPassword && (
													<span className={classes.errorHint}>
														{errors.confirmPassword}
													</span>
												)}
											</Grid>
											<Button
												data-testid='submit'
												type='submit'
												fullWidth
												variant='contained'
												color='primary'
												className={classes.buttonSubmit}
												disabled={!isValid || isSubmitting}
											>
												Submit
											</Button>
										</form>
									</Grid>
								</div>
							);
						}}
					</Formik>
				</Container>
			</div>
		)
	) : (
		<div>Please wait...</div>
	);
};

export default withStyles(styles)(ResetPassword);

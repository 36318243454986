import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

import Error from './Error/Error';

const PlainProtectedRoute = (props) => {
  const location = useLocation();
  const { isAuthenticated, isExpert } = props;
  return isAuthenticated ? (
    isExpert ? (
      <Outlet location />
    ) : (
      <Error errorMessage="You are not authorized to view this page!" />
    )
  ) : (
    <Navigate
      to={{
        pathname: '/login',
        state: location,
      }}
    />
  );
};

export default PlainProtectedRoute;

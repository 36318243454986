import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import isPdf from '../../../Utilities/isPdf';

import { tableStyles } from '../CaseDashboard.style';
import EnhancedTable from '../../Shared/EnhancedTable/EnhancedTable';
import {
  handleDownloadFile,
  openPdfFileInNewWindow,
} from '../../../Services/FileService';

const headCells = [
  { id: 'documentName', label: 'Document Name', width: '20%' },
  { id: 'description', label: 'Description', width: '20%' },
  { id: 'documentType', label: 'Document Type', width: '12%' },
  { id: 'uploadDate', label: 'Upload Date', width: '12%', isDate: true },
  { id: 'sharedBy', label: 'Shared By', width: '12%' },
  {
    id: 'expirationDate',
    label: 'Expiration Date',
    width: '12%',
    isDate: true,
  },
  { id: 'lastReviewed', label: 'Last Reviewed', width: '12%', isDate: true },
  { id: 'records', label: 'Records', width: '12%' },
];

const CaseDocuments = (props) => {
  const {
    classes,
    expertCaseDocumentsList = [],
    caseNo,
    isLoading,
    canDownloadFiles
  } = props;

  // action buttons
  function ViewButton(props) {
    const { classes, row, setRow, canDownloadFiles } = props;
    const [isViewingFile, setIsViewingFile] = useState(false);
    const [btnDownloading, setBtnDownloading] = useState(false);
    const [completed, setCompleted] = useState(0);
    const [variant, setVariant] = useState('indeterminate');
    const [isPdfFile, setIsPdfFile] = useState(false);
    const [disableDownloadButton, setDisableDownloadButton] = useState(false);

    useEffect(() => {

      const pdfFile = row && isPdf(row['documentName']);
      const disableDownload = !canDownloadFiles && pdfFile;
      setIsPdfFile(pdfFile);
      setDisableDownloadButton(disableDownload);

      console.debug('Case Document: inside of useEffect, fileName:', row? row['documentName']: '', 'isPdf:', pdfFile, 'canDownloadFiles:', canDownloadFiles, 'disableDownloadButton:', disableDownload, 'row:', row);

    },[row, canDownloadFiles])

    const onDownloadButtonClick = async () => {
      if (disableDownloadButton) return;

      await handleDownloadFile(
        btnDownloading,
        setBtnDownloading,
        setVariant,
        setCompleted,
        row,
        'lastReviewed',
        'depositoryId',
        setRow,
        caseNo
      );
    };

    const onOpenButtonClick = async () => {
      await openPdfFileInNewWindow(row, 'depositoryId', caseNo, 'lastReviewed', setRow, setIsViewingFile);
    };

    return (
      <div className={classes.viewSection}>
        <Button
          data-testid="caseRecordViewButton"
          variant="contained"
          size="small"
          className={classes.viewBtn}
          onClick={onOpenButtonClick}
          disabled={!isPdfFile}
        >
          {
            isViewingFile 
              ? (<CircularProgress
                  color="inherit"
                  size={24}
                  thickness={8}
                />) 
              : 'View'
          }
        </Button>

        <Button
          data-testid="caseDocumentDownloadButton"
          variant="contained"
          size="small"
          className={classes.viewBtn}
          disabled={!row['documentName'] || disableDownloadButton}
          onClick={onDownloadButtonClick}
        >
          {btnDownloading ? (
            <CircularProgress
              color="inherit"
              size={24}
              thickness={8}
              variant={variant}
              value={completed}
            />
          ) : (
            'Download'
          )}
        </Button>
      </div>
    );
  }

  const customCells = {
    records: <ViewButton classes={classes}  canDownloadFiles={canDownloadFiles}/>,
  };

  return (
    <div>
      <Paper elevation={0} className={classes.tableTitle}>
        Case Documents
      </Paper>
      <EnhancedTable
        rows={expertCaseDocumentsList}
        headCells={headCells}
        customCells={customCells}
        rowsPerPageOptions={[]}
        showPagination
        pageCount={5}
        isLoading={isLoading}
      />
    </div>
  );
};

export default withStyles(tableStyles)(CaseDocuments);

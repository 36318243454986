import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import BootstrapInput from '../Shared/BootstrapInput/BootstrapInput';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import styles from './ForgotPassword.style';
import { sendResetPasswordEmail } from '../../Services/UserService';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordComponent = (props) => {
    const navigate = useNavigate();
    const classes = props.classes;

    const [modalOpen, setModalOpen] = React.useState(true);

    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);

    const handleClose = () => {
        // close modal
        setModalOpen(false);
        // redirect to login
        navigate('/login');
    };

    const submitForm = async (values, { setSubmitting }) => {
        const { email } = values;

        try {
            await sendResetPasswordEmail(email);
            setSuccessAlertOpen(true);
        } catch (err) {
            setErrorAlertOpen(true);
            console.error(err);
        }
        setSubmitting(false);
    };

    return (
        <div className={classes.modalContainerBackground}>
            <div className={classes.containerTopSection}>
            </div>
            <Dialog PaperProps={{
                style: {
                    top: '-15vh',
                    boxShadow: 'none'
                },
            }} fullWidth aria-labelledby="forgot-password-modal" open={modalOpen} className={classes.forgotPasswordModal}>
                <DialogTitle className={classes.modalTitle}>FORGOT PASSWORD</DialogTitle>
                <Paper elevation={2} className={successAlertOpen ? classes.hidden : classes.forgotPasswordPaper}>
                    <Grid container spacing={1} className={classes.infoContainer}>
                        <Grid item xs={12}>
                            <p>
                                If you have lost or forgotten your password, enter your user ID and click Submit.
                                We will send a link to the email address on file to reset your password.
                        </p>
                        </Grid>
                    </Grid>
                    <Formik
                        initialValues={{
                            email: ''
                        }}

                        onSubmit={submitForm}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .strict(false)
                                .trim()
                                .required("User ID is required.")
                        })}
                    >
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            } = props;

                            const handleReset = (resetForm) => {
                                setErrorAlertOpen(false);
                                resetForm();
                            };

                            return (
                                <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <Grid container spacing={1} className={classes.inputContainer}>
                                        <Grid item xs={2}>
                                            <InputLabel shrink className={classes.inputLabel}>
                                                User ID *
                                    </InputLabel>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <FormControl required fullWidth>
                                                <BootstrapInput
                                                    id='email'
                                                    type="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete="off"
                                                    error={
                                                        errors.email &&
                                                        touched.email
                                                    }
                                                />
                                            </FormControl>
                                            {errors.email &&
                                                touched.email && (
                                                    <span
                                                        className={
                                                            classes.cueTextDanger
                                                        }
                                                    >
                                                        {
                                                            errors.email
                                                        }
                                                    </span>
                                                )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Collapse in={errorAlertOpen} id="errorAlert">
                                                <Alert 
                                                    className={classes.errorAlert} 
                                                    icon={false} 
                                                    variant="filled" 
                                                    severity="error"
                                                    action={
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={() => {
                                                                setErrorAlertOpen(false);
                                                            }}>
                                                        <CloseIcon fontSize="inherit" />
                                                        </IconButton>
                                                    }>
                                                    <div className={classes.alertCenteredText}>
                                                        User ID does not exist.
                                                    </div>
                                                </Alert>
                                            </Collapse>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                id="clearBtn"
                                                type="reset"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className={[
                                                    classes.modalButton,
                                                    classes.clearButton
                                                ].join(' ')}
                                                onClick={handleReset.bind(null, props.resetForm)}
                                            >
                                                Clear
                                </Button>
                                            <Button
                                                id="submitBtn"
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                                className={[
                                                    classes.modalButton,
                                                    classes.submitButton
                                                ].join(' ')}
                                            >
                                                Submit
                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            );
                        }}
                    </Formik>

                </Paper>
                <Paper
                    id="successAlert" 
                    elevation={2} 
                    className={successAlertOpen ? classes.forgotPasswordPaper : classes.hidden}>
                    <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <Alert 
                                className={classes.successAlert} 
                                icon={false} 
                                variant="filled" 
                                severity="success">
                                A reset password link has been sent to your email
                            </Alert>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={2} className={classes.forgotPasswordPaper}>
                    <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <Button
                                id="closeBtn"
                                onClick={handleClose}
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={[
                                    classes.modalButton,
                                    classes.closeButton
                                ].join(' ')}
                            >
                                Close
                    </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Dialog>
        </div>
    );
};


export default withStyles(styles)(ForgotPasswordComponent);
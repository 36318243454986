import React, { useState } from 'react';
import CaseManagerGrid from './CaseManagerGrid/CaseManagerGrid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import CaseManagerSearch from './CaseManagerSearch/CaseManagerSearch';
import styles from './CaseManager.style';
import { searchCases } from '../../Services/CaseService';

const CaseManager = props => {
	const { classes } = props;

	const [searchFilters, setSearchFilters] = useState({});

	const onFilterChange = searchParams => {
		setSearchFilters(searchParams);
	};

	const fetchPromise = page => {
		return new Promise(async (resolve, reject) => {
			try {
				const casesRes = await searchCases({
					...searchFilters,
					pageNumber: page
				});

				const { items, size } = casesRes.data;
				resolve({ rows: items, rowCount: size });
			} catch (err) {
				console.error(err);
				reject(err);
			}
		});
	};

	return (
		<div className={classes.root}>
			<Paper elevation={0} className={classes.titlePaper}>
				<h4 className={classes.title}>Case Manager</h4>
			</Paper>
			<CaseManagerSearch onFilterChange={onFilterChange} />
			<CaseManagerGrid
				fetchRowsPromise={fetchPromise}
			/>
		</div>
	);
};

export default withStyles(styles)(CaseManager);

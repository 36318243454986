import axios from 'axios';
import moment from 'moment';
import loader from '../assets/loading.gif';

const settings = window.APP_SETTINGS;

export const getDownloadLink = (fileId, caseNo) => {
  return axios.post(`${settings.MYO_API_BASE_URL}/files/expsign`, null, {
    params: { fileId, caseNo },
  });
};

export const downloadFile = (url, onDownloadProgress) => {
  return axios.get(url, { responseType: 'blob', onDownloadProgress });
};

export const handleDownloadFile = async (
  inProgress,
  setInprogress,
  setVariant,
  setCompleted,
  row,
  lastReviewedProp,
  depositoryNoProp,
  setRow,
  caseNo,
  trackingNo = null
) => {
  await GetFile(
    inProgress,
    setInprogress,
    setVariant,
    setCompleted,
    row,
    lastReviewedProp,
    depositoryNoProp,
    setRow,
    caseNo,
    trackingNo,
    'application/octet-stream'
  );
};

export const openPdfFileInNewWindow = async (
  row,
  depositoryNoProp,
  caseNo,
  lastReviewedProp,
  setRow,
  setIsViewingFile,
  trackingNo = null
) => {
  try{
    setIsViewingFile(true);
    let newWin = window.open("", "_blank");
    newWin.document.body.background = loader;
    newWin.document.body.style.backgroundRepeat = "no-repeat";
    newWin.document.body.style.backgroundPosition = "center";

    const res = await getDownloadLink(row[depositoryNoProp], caseNo);
    let url = res.data.url;
  
    if (trackingNo) {
      url += `&trackingNo=${trackingNo}`;
    }

    newWin.location.href = `/pdf-viewer?pdf=${encodeURIComponent(url)}`;
    
    row[lastReviewedProp] = moment();
    setRow(row);
    setIsViewingFile(false);
  } catch (err) {
    setIsViewingFile(false);
    console.error(err);
  }
};

const viewFile = (data, fileName, type, newWindow = null) => {
  const blob = new Blob([data], { type });

  if (type === 'application/pdf') {
    if (newWindow) {
      newWindow.location.href = '/pdf-viewer';
      newWindow.postMessage({ pdfFile: blob, fileName }, '*');
    }
    return;
  }

  // Internet Explorer
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
    // Other browsers
  } else {
    const url = window.URL.createObjectURL(blob);

    if (newWindow) newWindow.location.href = url;
    else {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_self';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const GetFile = async (
  inProgress,
  setInprogress,
  setVariant,
  setCompleted,
  row,
  lastReviewedProp,
  depositoryNoProp,
  setRow,
  caseNo,
  trackingNo,
  type,
  newWin = null
) => {
  if (inProgress) return;

  setInprogress(true);
  const orgLastReviewedValue = row[lastReviewedProp];
  try {
    const res = await getDownloadLink(row[depositoryNoProp], caseNo);
    let url = res.data.url;

    if (trackingNo) {
      url += `&trackingNo=${trackingNo}`;
    }
    const downloadRes = await downloadFile(url, (e) => {
      setVariant('determinate');
      setCompleted(Math.floor((e.loaded / e.total) * 100));
    });

    setVariant('indeterminate');
    setCompleted(0);

    const filename = downloadRes.headers['x-file-name'];
    viewFile(downloadRes?.data, filename, type, newWin, url);

    row[lastReviewedProp] = moment();
    setRow(row);
    setInprogress(false);
  } catch (err) {
    row[lastReviewedProp] = orgLastReviewedValue;
    setRow(row);
    setInprogress(false);
    if (newWin) newWin.close();
    console.error(err);
  }
};

import React, { useState, useEffect } from "react";
import CaseInfo from "./CaseInfo/CaseInfo";
import CaseRecords from "./CaseRecords/CaseRecords";
import CaseDocuments from "./CaseDocuments/CaseDocuments";
import { getExpertCase } from "../../Services/CaseService";
import DicomStudies from "./DicomStudies/DicomStudies";
import { useLocation } from "react-router-dom";

const CaseDashboard = () => {
  const location = useLocation();
  const queryStr = location.pathname;
  const str = queryStr.split("/");
  const caseNo = parseInt(str[2]);
  const [expertCaseInfo, setExpertCaseInfo] = useState();
  const [expertRecords, setExpertRecords] = useState([]);
  const [expertCaseDocuments, setExpertCaseDocuments] = useState([]);
  const [expertDicomStudies, setExpertDicomStudies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [displayDicomStudies, setDisplayDicomStudies] = useState(false);
  const [recordsOf, setRecordsOf] = useState('');
  const [canDownloadFiles, setCanDownloadFiles] = useState(true);

  useEffect(() => { 
    const getExpertCaseData = async () => {
      setIsLoading(true);
      try {
        const response = await getExpertCase(caseNo);
        const expertCaseInfoObj = {
          orderNo: response.data.orderNo,
          recordsOf: response.data.recordsOf,
          claimNo: response.data.claimNo,
          firmFileNo: response.data.firmFileNo,
          chronFileRequestId: response.data.chronFileRequestId,
        };
        setCanDownloadFiles(response.data.canDownloadFiles);
        setRecordsOf(response.data.recordsOf);
        setExpertCaseInfo(expertCaseInfoObj);
        setExpertRecords(response.data.partLevelDocuments);
        setExpertCaseDocuments(response.data.caseLevelDocuments);
        setExpertDicomStudies(response.data.dicomStudies);

        if(response.data.dicomStudies && response.data.dicomStudies.length)
          setDisplayDicomStudies(true);

        setIsLoading(false);

      } catch (error) {
        setIsLoading(false);
        if (error.toString().includes("401")) {
          // Redirect to Un Authorized Page
        }
      }
    };

    getExpertCaseData();
  }, [caseNo]);

  return (
    <div>
      <CaseInfo expertCaseInfoObj={expertCaseInfo} />
      <CaseRecords
        expertRecordsList={expertRecords}
        caseNo={caseNo}
        isLoading={isLoading}
        recordsOf={recordsOf}
        canDownloadFiles={canDownloadFiles}
        chronFileRequestId = {expertCaseInfo?.chronFileRequestId}
      />
      {displayDicomStudies ? (
        <DicomStudies
          studies={expertDicomStudies}
          caseNo={caseNo}
          isLoading={isLoading}
        />
      ) : (
        ""
      )}
      <CaseDocuments
        expertCaseDocumentsList={expertCaseDocuments}
        caseNo={caseNo}
        isLoading={isLoading}
        canDownloadFiles={canDownloadFiles}
      />
    </div>
  );
};

export default CaseDashboard;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import styles from './InvalidLink.style';
import { useLocation } from 'react-router-dom';

const InvalidLink = (props) => {
    const location = useLocation();
    const errorMsg = location.state? location.state.errorMsg : 'Invalid link.';
    const classes  = props.classes;
    const modalOpen = true;

    return (
        <Dialog aria-labelledby="invalid-link-title" open={modalOpen}>
            <DialogTitle className={classes.modalTitle}>Invalid Link</DialogTitle>
            <Paper elevation={2} className={classes.invalidLinkPaper}>
                <Grid container spacing={1} className={classes.infoContainer}>
                    <Grid item>
                        <div>
                            <img
                                src='/images/MyOntellus_Logo-Portal_GrayText.png'
                                alt='Ontellus Expert Portal'
                                title='Ontellus Expert Portal'
                                className={classes.loginLogo}
                            ></img>
                        </div>
                        <Alert className={classes.errorAlert} icon={false} variant="filled" severity="error">
                            {errorMsg}</Alert>
                    </Grid>
                </Grid>
            </Paper>
        </Dialog>
    );
};


export default withStyles(styles)(InvalidLink);
const styles = theme => ({
	// Reset Complete
	root: {
		backgroundImage: 'url(./images/testimonial-woman-.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
		padding: theme.spacing(10, 0),
		display: 'flex',
		flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100%'
	},
	boxPaper: {
        padding: theme.spacing(4),
		margin: theme.spacing(2, 0),
		background: '#f5f5f5',
		border: '1px solid #e3e3e3',
		borderRadius: 5,
		maxWidth: '540px',
		boxShadow: 'none'
	},
	msgPaper: {
		margin: theme.spacing(4, 1, 1, 1),
		padding: theme.spacing(2),
		backgroundColor: '#18bc9c',
		color: '#fff',
		maxWidth: '380px',
		boxShadow: 'none'
	},
	msgLink: {
		textDecoration: 'underline',
		color: '#fff'
	}
});

export default styles;
